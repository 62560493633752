import { adminNavigationRoutes } from '../utils/adminToWebRouteMapping';

/**
 * This method allows us to define the redirection logic, when a user
 * with no access rights for a given route try to access it
 * @param {ARRAY(STRING)} permissions
 */
export const permissionsRedirection = (permissions, isParametric, to) => {
  if (!permissions || !permissions.length) {
    return 'login';
  }

  // User wants to go to admin contents/cases pages
  // Redirect to web or web-parametric
  // for proper redirection from computeRedirectionWithParams
  if (
    to &&
    !permissions.includes('EDIT_KNOWLEDGE_BASE') &&
    adminNavigationRoutes.includes(to.name)
  ) {
    return isParametric ? 'web-filter' : 'mayday-web';
  }

  if (permissions.includes('EDIT_KNOWLEDGE_BASE')) {
    return 'home-v2';
  } else if (permissions.includes('READ_ANALYTICS_DASHBOARDS')) {
    return 'dashboard';
  } else if (permissions.includes('EDIT_AGENT_NOTIFICATION')) {
    return 'notification-center';
  } else if (
    permissions.includes('EDIT_COMPANY_SETTINGS') ||
    permissions.includes('EDIT_COMPANY_USERS')
  ) {
    return 'settings';
  } else if (permissions.includes('READ_TASK')) {
    return 'hub';
  } else if (
    [
      'CREATE_MODULE',
      'UPDATE_MODULE',
      'DELETE_MODULE',
      'ASSIGN_MODULE',
      'RESTRICT_MODULE',
      'PRESENT_MODULE',
    ].some((permission) => permissions.includes(permission))
  ) {
    return 'academy';
  } else {
    if (isParametric) {
      return 'web-filter';
    }
    return 'mayday-web';
  }
};
