import gql from 'graphql-tag';

export default {
  getContentVersions: gql`
    query getContentVersionsQuery(
      $contentId: ID!
      $pageSize: Int
      $page: Int
      $lang: String
      $stagedDataVersion: Int
    ) {
      getContentVersions(
        contentId: $contentId
        pageSize: $pageSize
        page: $page
        lang: $lang
        stagedDataVersion: $stagedDataVersion
      ) {
        total
        pages
        page
        results {
          id
          label
          body
          stagedDataVersion
          isStagedDataPublish
          author
          createdAt
          updatedAt
        }
      }
    }
  `,

  getContentVersionsContributorIds: gql`
    query getContentVersionsContributorIdsQuery(
      $contentId: ID!
      $knowledgeId: ID!
      $lang: String
    ) {
      getContentVersionsContributorIds(
        contentId: $contentId
        knowledgeId: $knowledgeId
        lang: $lang
      )
    }
  `,
};
