export class ContentVersionService {
  constructor(data) {
    this.data = data;
  }

  getContentVersions(
    contentId,
    pageSize = 10,
    page = 1,
    lang,
    stagedDataVersion,
  ) {
    return this.data.getContentVersions(
      contentId,
      pageSize,
      page,
      lang,
      stagedDataVersion,
    );
  }

  getContributorIds(contentId, knowledgeId, lang) {
    return this.data.getContentVersionsContributorIds(
      contentId,
      knowledgeId,
      lang,
    );
  }
}
