<template>
  <div
    v-if="open"
    class="headless-modal-overlay"
    :class="[customClass]"
    @mousedown.self="handleClose"
  >
    <div class="headless-modal-content">
      <slot>
        <!-- Default slot content -->
        <div style="background-color: white; padding: 20px; border-radius: 5px">
          <h2>Default Modal Content</h2>
          <p>Click outside the modal to close it.</p>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeadlessModal',

  props: {
    open: {
      type: Boolean,
      required: true,
    },
    customClass: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleEscape(e) {
      if (e.key === 'Escape') {
        this.$emit('close');
      }
    },
    handleClose() {
      this.$emit('close');
    },
  },
  watch: {
    open: {
      handler(open) {
        if (open) {
          window.addEventListener('keydown', this.handleEscape);
          document.body.classList.add('overflow-hidden');
        } else {
          window.removeEventListener('keydown', this.handleEscape);
          document.body.classList.remove('overflow-hidden');
        }
      },
      immediate: true,
    },
  },
  destroyed() {
    window.removeEventListener('keydown', this.handleEscape);
    document.body.classList.remove('overflow-hidden');
  },
};
</script>

<style>
/* Native CSS for Tailwind styles */
.headless-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2147483645; /* Equivalent to calc(2147483647-2) */
  padding: 10px;
  cursor: pointer;
}

.headless-modal-content {
  max-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: auto;
}
</style>
