import gql from 'graphql-tag';

export default {
  exportsPredefinedFilters: gql`
    query exportsPredefinedFiltersQuery($exportType: String) {
      exportsPredefinedFilters(exportType: $exportType) {
        id
        name
      }
    }
  `,
  exportGlobalAttempts: gql`
    query exportGlobalAttemptsQuery($predefinedFilterId: Int) {
      exportGlobalAttempts(predefinedFilterId: $predefinedFilterId) {
        file
        error
      }
    }
  `,
};
