<template>
  <div class="base-callout" :class="type">
    <font-awesome-icon :icon="['fas', icon]" class="base-callout-icon" />
    <slot>
      <span>{{ text }}</span>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'base-callout',
  props: {
    type: {
      type: String,
      default: 'warning',
    },
    text: {
      type: String,
    },
  },
  data() {
    return {
      icones: {
        warning: 'exclamation-triangle',
        danger: 'exclamation-circle',
        info: 'info-circle',
      },
    };
  },
  computed: {
    icon() {
      return this.icones[this.type];
    },
  },
};
</script>

<style lang="scss" scoped>
.base-callout {
  padding: 10px 16px;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  margin: 0 auto;
  font-size: 12px;
  line-height: 16px;

  &.warning {
    background-color: #fcbf491a;
  }

  &.danger {
    background-color: #fff1f2ff;

    .base-callout-icon {
      color: $red-mayday;
    }
  }

  &-icon {
    margin-top: 2px;
    margin-right: 8px;
  }
}
</style>
