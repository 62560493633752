import * as types from '../mutation-types';
import moment from 'moment';
import { deepCopy, isPrimitive } from '../utils';
import { httpStatusCodes } from '../../../utils/httpStatuses';

const iteratorFn = (defaultKnowledgeId, startDate, endDate) => {
  const iterator = (
    filterValues,
    { field, filters = [], type, key, values },
  ) => {
    if ((filters != null && !!filters.length) || type === 'range') {
      const lastIdx = filterValues.push([]) - 1;
      filters.forEach(({ field, filters = [], type: _type, ...rest }) => {
        const type = type === 'range' ? type : _type;
        return iterator(filterValues[lastIdx], {
          field,
          filters,
          type,
          ...rest,
        });
      });
    }
    if (field === 'value') {
      if (key === 'knowledgeId') {
        const defaultIsRestricted = values.some(
          ({ value, userPermissions }) =>
            value === defaultKnowledgeId &&
            (!userPermissions || !userPermissions.canReadAnalyticsDashboards),
        );

        const knowledgeId = defaultIsRestricted
          ? values.find(
              ({ userPermissions }) =>
                userPermissions && userPermissions.canReadAnalyticsDashboards,
            ).value
          : defaultKnowledgeId;

        filterValues.push(knowledgeId);
      } else if (key === 'startDate') {
        filterValues.push(startDate);
      } else if (key === 'endDate') {
        filterValues.push(endDate);
      } else {
        filterValues.push('');
      }
    } else if (field === 'values') {
      let valuesToPush = [];
      if (key === 'groupIds' || key === 'labelIds') {
        valuesToPush = values.map(({ value }) => value);
      }
      filterValues.push(valuesToPush);
    }
    return filterValues;
  };
  return iterator;
};

const generateFilterBaseValues = (
  filtersList = [],
  defaultKnowledgeId,
  defaultDateRange = [2, 'weeks'],
  allowToday = false,
) => {
  let format = 'YYYY-MM-DD';
  if (defaultDateRange[1] === 'hours') format = 'YYYY-MM-DDTHH:mm:ss';
  const startDate = moment()
    .subtract(...defaultDateRange)
    .format(format);
  let endDate = moment();
  if (allowToday) {
    endDate = endDate.format(format);
  } else {
    endDate = endDate.subtract(1, 'days').format(format);
  }

  return filtersList.reduce(
    iteratorFn(defaultKnowledgeId, startDate, endDate),
    [],
  );
};

const generateFilterDateRangeShortcutValues = () => {
  const endDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
  const oneWeekBeginDate = moment().subtract(8, 'days').format('YYYY-MM-DD');
  const twoWeekBeginDate = moment().subtract(15, 'days').format('YYYY-MM-DD');
  const oneMonthBeginDate = moment()
    .subtract(1, 'months')
    .subtract(1, 'days')
    .format('YYYY-MM-DD');
  return {
    'one-week': [oneWeekBeginDate, endDate],
    'two-weeks': [twoWeekBeginDate, endDate],
    'one-month': [oneMonthBeginDate, endDate],
  };
};

const generateFilterTimeRangeShortcutValues = () => {
  const format = 'YYYY-MM-DDTHH:mm:ss';
  const endTime = moment().format(format);
  const sixHours = moment().subtract(6, 'hours').format(format);
  const oneDay = moment().subtract(1, 'days').format(format);
  const oneWeek = moment().subtract(7, 'days').format(format);

  return {
    'six-hours': [sixHours, endTime],
    'one-day': [oneDay, endTime],
    'one-week': [oneWeek, endTime],
  };
};

const generateKnowledgeFallbackValue = (filters) => {
  const generalFilters = filters.find((filter) => filter.label === 'general');

  if (!generalFilters) return '';

  const knowledgeFilters = generalFilters.filters.find(
    (filter) => filter.key === 'knowledgeId',
  );

  if (
    !knowledgeFilters ||
    !knowledgeFilters.values ||
    !knowledgeFilters.values.length
  ) {
    return '';
  }

  return knowledgeFilters.values[0].value;
};

const generateAdditionalValues = (filters) => {
  const additionalValues = filters.reduce((acc, filter) => {
    filter.filters.forEach(({ condition, isRemote, label }) => {
      if (condition) {
        acc[`${label}Condition`] = condition;
      }
      if (isRemote) {
        acc[`${label}Remote`] = '';
      }
    });
    return acc;
  }, {});

  additionalValues.mergeTerms = true;

  return additionalValues;
};

export const prepareContentUsageFilters = async function ({
  commit,
  state,
  rootGetters,
  dispatch,
}) {
  try {
    const filters = await this.$services.dashboardFilters.getDashboardFilters();

    commit(types.SET_FILTERS, Array.from(filters));
    const defaultKnowledge = rootGetters['knowledgeModule/focusKnowledge'];
    const values = generateFilterBaseValues(
      state.filters,
      defaultKnowledge
        ? defaultKnowledge.id
        : generateKnowledgeFallbackValue(filters),
    );
    const additionalValues = generateAdditionalValues(state.filters);
    const defaultValues = deepCopy(values);
    const defaultAdditionalValues = deepCopy(additionalValues);
    commit(types.SET_FILTERS_VALUES, { values, defaultValues });
    commit(types.SET_FILTERS_ADDITIONAL_VALUES, {
      additionalValues,
      defaultAdditionalValues,
    });
    dispatch('handlePublicFilters');

    commit(
      types.SET_FILTERS_DATE_RANGE_SHORTCUT_VALUES,
      generateFilterDateRangeShortcutValues(),
    );
  } finally {
    commit(types.SET_FILTERS_LOADING, false);
  }
};

export const prepareAskFilters = function ({ commit, state, dispatch }) {
  const filters = [
    {
      label: 'general',
      filters: [
        {
          label: 'date',
          isRemote: false,
          type: 'DateRange',
          field: 'range',
          filters: [
            {
              label: 'start_date',
              key: 'startDate',
              isRemote: false,
              allowToday: true,
              type: 'Date',
              field: 'value',
              value: null,
              values: null,
            },
            {
              label: 'end_date',
              key: 'endDate',
              isRemote: false,
              type: 'Date',
              field: 'value',
              value: null,
              values: null,
            },
          ],
        },
      ],
    },
  ];
  commit(types.SET_FILTERS, filters);
  const values = generateFilterBaseValues(
    filters,
    generateKnowledgeFallbackValue(filters),
    [2, 'weeks'],
    true,
  );
  const additionalValues = generateAdditionalValues(state.filters);
  const defaultValues = deepCopy(values);
  const defaultAdditionalValues = deepCopy(additionalValues);

  commit(types.SET_FILTERS_VALUES, { values, defaultValues });
  commit(types.SET_FILTERS_ADDITIONAL_VALUES, {
    additionalValues,
    defaultAdditionalValues,
  });
  dispatch('handlePublicFilters');

  commit(
    types.SET_FILTERS_DATE_RANGE_SHORTCUT_VALUES,
    generateFilterDateRangeShortcutValues(),
  );
  commit(types.SET_FILTERS_LOADING, false);
};

export const prepareDownloadFilters = async function ({
  commit,
  state,
  dispatch,
}) {
  try {
    const downloadFilterValues =
      await this.$services.downloadAnalytics.getExportFilters();

    const filters = [
      {
        label: 'general',
        filters: [
          {
            label: 'date',
            isRemote: false,
            type: 'DateRange',
            field: 'range',
            filters: [
              {
                label: 'start_date',
                key: 'startDate',
                isRemote: false,
                allowToday: true,
                type: 'Date',
                field: 'value',
                value: null,
                values: null,
              },
              {
                label: 'end_date',
                key: 'endDate',
                isRemote: false,
                type: 'Date',
                field: 'value',
                value: null,
                values: null,
              },
            ],
          },
        ],
      },
      {
        label: 'exports',
        filters: [
          {
            label: 'periodicity',
            isRemote: false,
            type: 'Object',
            field: 'values',
            filters: null,
            value: null,
            key: 'exportPeriodicity',
            values: downloadFilterValues.exportPeriodicity.map(
              (periodicity) => ({
                label: `filters.groups.periodicity.${periodicity}`,
                value: periodicity,
              }),
            ),
          },
          {
            label: 'type',
            isRemote: false,
            type: 'Object',
            field: 'values',
            filters: null,
            value: null,
            key: 'exportType',
            values: downloadFilterValues.exportType.map((type) => ({
              label: `filters.groups.type.${type}`,
              fallbackLabel: type,
              value: type,
            })),
          },
        ],
      },
    ];
    commit(types.SET_FILTERS, filters);
    const values = generateFilterBaseValues(
      filters,
      generateKnowledgeFallbackValue(filters),
      [1, 'months'],
      true,
    );
    const additionalValues = generateAdditionalValues(state.filters);
    const defaultValues = deepCopy(values);
    const defaultAdditionalValues = deepCopy(additionalValues);

    commit(types.SET_FILTERS_VALUES, { values, defaultValues });
    commit(types.SET_FILTERS_ADDITIONAL_VALUES, {
      additionalValues,
      defaultAdditionalValues,
    });
    dispatch('handlePublicFilters');

    commit(
      types.SET_FILTERS_DATE_RANGE_SHORTCUT_VALUES,
      generateFilterDateRangeShortcutValues(),
    );
  } finally {
    commit(types.SET_FILTERS_LOADING, false);
  }
};

const resourcesToFilters = (resources) => {
  return [
    {
      label: 'resources',
      filters: [
        {
          label: 'environment',
          isRemote: false,
          type: 'Object',
          field: 'values',
          filters: null,
          value: null,
          key: 'environmentIds',
          values: resources.reduce((acc, resource) => {
            if (!acc.find((el) => el.value === resource.environment.id))
              acc.push({
                label: resource.environment.name,
                key: null,
                value: resource.environment.id,
                isPublic: null,
                tagAttributes: null,
              });
            return acc;
          }, []),
        },
        {
          label: 'resource',
          isRemote: false,
          type: 'Object',
          field: 'values',
          filters: null,
          value: null,
          key: 'resourceIds',
          values: resources.map((resource) => ({
            label: resource.title,
            key: null,
            value: resource.id,
            isPublic: null,
            tagAttributes: null,
            environmentId: resource.environment.id,
          })),
        },
        {
          label: 'status',
          isRemote: false,
          type: 'MultiSelect',
          field: 'values',
          filters: null,
          value: null,
          key: 'statusCodes',
          values: httpStatusCodes.map((status) => ({
            label: status,
            value: status,
            key: `status-${status}`,
          })),
        },
        {
          label: 'function',
          isRemote: false,
          type: 'Object',
          field: 'values',
          filters: null,
          value: null,
          key: 'functionIds',
          values: resources.flatMap((resource) =>
            resource.functions.map((fun) => ({
              label: fun.title,
              description: `${fun.config.method.toUpperCase()} ${
                fun.config.url
              }`,
              key: null,
              value: fun.id,
              isPublic: null,
              tagAttributes: null,
              resourceId: resource.id,
              environmentId: resource.environment.id,
            })),
          ),
        },
      ],
    },
  ];
};

export const prepareWorkflowFilters = async function ({
  commit,
  state,
  dispatch,
}) {
  const baseFilters = [
    {
      label: 'general',
      filters: [
        {
          label: 'date',
          isRemote: false,
          type: 'TimeRange',
          field: 'range',
          filters: [
            {
              label: 'start_date',
              key: 'startDate',
              isRemote: false,
              allowToday: true,
              type: 'Date',
              field: 'value',
              value: null,
              values: null,
            },
            {
              label: 'end_date',
              key: 'endDate',
              isRemote: false,
              type: 'Date',
              field: 'value',
              value: null,
              values: null,
            },
          ],
        },
      ],
    },
  ];
  try {
    const resources = await this.$services.resources.getAll();
    commit(types.SET_RESOURCES, resources);
    const filters = [...baseFilters, ...resourcesToFilters(resources)];
    commit(types.SET_FILTERS, filters);
    const values = generateFilterBaseValues(
      filters,
      generateKnowledgeFallbackValue(filters),
      [6, 'hours'],
      true,
    );
    const additionalValues = generateAdditionalValues(state.filters);
    const defaultValues = deepCopy(values);
    const defaultAdditionalValues = deepCopy(additionalValues);

    commit(types.SET_FILTERS_VALUES, { values, defaultValues });
    commit(types.SET_FILTERS_ADDITIONAL_VALUES, {
      additionalValues,
      defaultAdditionalValues,
    });
    dispatch('handlePublicFilters');

    commit(
      types.SET_FILTERS_DATE_RANGE_SHORTCUT_VALUES,
      generateFilterTimeRangeShortcutValues(),
    );
    commit(types.SET_FILTERS_LOADING, false);
  } finally {
    commit(types.SET_FILTERS_LOADING, false);
  }
};

export const prepareFilters = function ({ commit, state, dispatch }) {
  commit(types.SET_FILTERS_LOADING, true);
  if (state.activeTab === 'ask') return dispatch('prepareAskFilters');
  if (state.activeTab === 'download-table')
    return dispatch('prepareDownloadFilters');
  if (state.activeTab === 'workflow') return dispatch('prepareWorkflowFilters');
  dispatch('prepareContentUsageFilters');
};

export const updateFilterValue = ({ commit, dispatch }, context) => {
  commit(types.SET_FILTERS_VALUE, context);
  dispatch('handlePublicFilters');
};

export const updateAdditionalFilterValue = (
  { commit, state },
  additionalValue,
) => {
  commit(types.SET_FILTERS_ADDITIONAL_VALUE, additionalValue);

  const { i, j, key, value } = additionalValue;
  const currentValues = state.filterValues[i][j];

  if (
    key.endsWith('Condition') &&
    value === 'AND' &&
    currentValues.length > 1
  ) {
    const copy = [...currentValues].filter((v) => v !== '0');
    commit(types.SET_FILTERS_VALUE, { i, j, value: copy });
  }
};

export const handlePublicFilters = ({ state, getters, dispatch, commit }) => {
  const filters = [...state.filters];
  const usersFilterGroup = filters.find((filter) => filter.label === 'users');
  if (!usersFilterGroup) {
    return;
  }

  if (getters.isPublicSelected) {
    usersFilterGroup.shouldHide = true;
    commit(types.SET_FILTERS, filters);
    const usersFilters = state.filterValues[1];

    usersFilters.forEach((_filter, index) =>
      dispatch('resetFilterValue', { i: 1, j: index }),
    );
  } else {
    usersFilterGroup.shouldHide = false;
    commit(types.SET_FILTERS, filters);
  }
};

export const resetFilterValue = (
  { commit, state },
  { i, j, unselect, label },
) => {
  if (unselect) {
    return commit(types.SET_FILTERS_VALUE, { i, j, value: [] });
  }

  const newValue = state.defaultFilterValues[i][j];
  let defaultValue = isPrimitive(newValue) ? newValue : deepCopy(newValue);

  if (state.filterAdditionalValues[`${label}Condition`] === 'AND') {
    defaultValue = defaultValue.filter((v) => v !== '0');
  }

  commit(types.RESET_FILTERS_VALUE, { i, j, defaultValue });
};
