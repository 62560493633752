<template>
  <div class="logo-container">
    <a v-if="userAccess.home" :href="homeLink"
      ><img class="mayday-logo" src="/img/brand/logo/mayday-logo-blue.svg"
    /></a>
    <router-link v-else :to="{ path: redirectToHome }">
      <img class="mayday-logo" src="/img/brand/logo/mayday-logo-blue.svg" />
    </router-link>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { permissionsRedirection } from '@/utils/permissionsRedirection';

export default {
  name: 'home-redirection',
  computed: {
    redirectToHome() {
      const routeName = permissionsRedirection(
        this.userPermissions,
        this.isParametric,
      );

      const routeData = this.$router.resolve(
        {
          name: routeName,
          params: { lang: this.editingLanguage },
        },
        this.$route,
      );
      return routeData.href;
    },
    homeLink() {
      if (!this.focusKnowledge) return '/home';
      return `/home/${this.editingLanguage}/${this.focusKnowledge.id}`;
    },
    ...mapState(['userPermissions']),
    ...mapGetters(['isParametric', 'userAccess']),
    ...mapGetters('knowledgeModule', ['editingLanguage', 'focusKnowledge']),
  },
};
</script>

<style lang="scss" scoped>
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}
.mayday-logo {
  width: 32px;
}
</style>
