/* eslint-disable max-len */
import * as types from '../mutation-types';

export const updateFocusPath = ({ commit }, focusPath) =>
  commit(types.UPDATE_FOCUS_PATH, {
    focusPath: focusPath.map(String),
  });

export const updateCasePath = ({ commit }, casePath) => {
  commit(types.UPDATE_CASE_PATH, {
    casePath,
  });
};

export const pushContentToCasePath = ({ commit, getters }, content) => {
  const { casePath } = getters;

  if (!casePath || !casePath.length) return;

  const length = casePath.length;

  const lastItem = casePath[length - 1];

  if (lastItem.id === content.id) return;

  // === IsContent
  if (lastItem.type) casePath.pop();

  casePath.push(content);

  commit(types.UPDATE_CASE_PATH, {
    casePath,
  });
};

export const updateDiagPath = ({ commit }, diagPath) => {
  commit(types.UPDATE_DIAG_PATH, {
    diagPath,
  });
};

export const updateKnowledgeGroups = ({ commit }, groups) =>
  commit(types.UPDATE_KNOWLEDGE_GROUPS, {
    groups,
  });

export const updateMagicAnswers = ({ commit }, magicAnswers) =>
  commit(types.UPDATE_MAGIC_ANSWERS, {
    magicAnswers,
  });

export const updateEmbeddableExtensionClick = ({ commit }, src) =>
  commit(types.UPDATE_EMBEDDABLE_EXTENSION_CLICK, src);

export const addCheckedTreeNode = ({ commit, state }, newCheckedTreeNode) => {
  const {
    checkedTreeNodes,
    focusKnowledge: { rootId },
  } = state;
  if (newCheckedTreeNode.id !== rootId) {
    commit(types.UPDATE_CHECKED_TREE_NODES, {
      checkedTreeNodes: checkedTreeNodes.concat([newCheckedTreeNode]),
    });
  }
};

export const removeCheckedTreeNode = ({ commit, state }, { id, parentIds }) => {
  const { checkedTreeNodes } = state;
  commit(types.UPDATE_CHECKED_TREE_NODES, {
    checkedTreeNodes: checkedTreeNodes
      .filter((o) => !o.parentIds.includes(id))
      .filter((o) => !parentIds.includes(o.id))
      .filter((o) => o.id !== id),
  });
};

export const resetCheckedTreeNodes = ({ commit }) =>
  commit(types.UPDATE_CHECKED_TREE_NODES, {
    checkedTreeNodes: [],
  });

export const updateKnowledgeFilters = ({ commit }, knowledgeFilters) =>
  commit(types.UPDATE_KNOWLEDGE_FILTERS, {
    knowledgeFilters,
  });

export const updateShowKnowledgeFilters = ({ commit }, showKnowledgeFilters) =>
  commit(types.UPDATE_SHOW_KNOWLEDGE_FILTERS, {
    showKnowledgeFilters,
  });

export const logout = ({ commit }) => commit(types.LOGOUT);
