import * as types from './mutation-types';

export default {
  async getBacklogTasksCount({ commit }) {
    try {
      const backlogTasksCount =
        await this.$services.tasks.getBacklogTasksCount();
      commit(types.GET_BACKLOG_TASKS_COUNT, { backlogTasksCount });
    } catch (e) {
      return e;
    }
  },
};
