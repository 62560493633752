import * as types from './mutation-types';
import initState from './state';

export default {
  [types.LOGOUT](state, resetBacklog = true) {
    Object.assign(state, {
      ...initState(),
      backlogTasksCount: resetBacklog ? 0 : state.backlogTasksCount,
    });
  },

  [types.GET_BACKLOG_TASKS_COUNT](state, payload) {
    const { backlogTasksCount } = payload;
    state.backlogTasksCount = backlogTasksCount;
  },
};
