import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import * as getters from './getters';
import mutations from './mutations';
import * as actions from './actions';
import Sockets from './sockets';

import adminModule from './adminModule';
import { employeesTableFetchPlugin } from './adminModule/plugins';

import knowledgeModule from './knowledgeModule';
import { knowledgeFetchPlugin } from './knowledgeModule/plugins';

import webModule from './webModule';
import webKnowledgeModule from './webKnowledgeModule';
import taskModule from './taskModule';
import kbStore from './kb';
import dashboard from './dashboard';
import { dashboardTabDataFetchPlugin } from './dashboard/plugins';

import websocketModule from './websocketModule';
import { websocketInitPlugin } from './websocketModule/plugin';

import modalsModule from './modalsModule';

import publicKnowledgeModule from './publicKnowledgeModule';
import { publicKnowledgePlugin } from './publicKnowledgeModule/plugin';

import adminUserDirectoryModule from './adminUserDirectoryModule';

import attachmentModule from './attachmentModule';
import threadModule from './threadModule';

import brainModule from './brainModule';

import kbExportModule from './kbExport';

import academyModule from './academyModule';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // VERSIONING
    blobVersion: {},
    versionPollingTimeoutId: null,

    // CUSTOM CONFIG
    customConfig: {},

    // AUTH
    // -- NEW
    accessToken: null,
    accessExpirationDate: null,
    refreshToken: null,

    // -- DEPRECATED
    searchAccess: null,
    searchIndex: null,

    // USER
    appLanguage: 'fr',
    userId: null,
    username: null,
    avatar: null,
    userRole: null,
    userPermissions: [],
    isVerified: false,
    userGroups: [],
    userCreatedDate: null,
    userRecentlyConsultedContents: { items: {}, identification: {} },
    userPreferences: [],

    // COMPANY
    companyId: null,
    isParametric: true,
    status: null,
    integrations: null,
    headerSearch: null,
    rootId: null,
    roots: null,
    languages: null,
    defaultLanguage: null,
    roles: [],
    companyAttributes: [],
    focusPath: [],
    focusTree: {},
    groups: [], // deprecated
    magicAnswers: [],
    embeddableExtensionClickEvent: null,
    companyPreferences: [],
    checkedTreeNodes: [],
    treeNodes: [],
    nodesContent: [],
    knowledgeFilters: [],
    showKnowledgeFilters: false,
    collaboratorsFocus: [],
    analyticsFilters: null,
    authAttempts: 0,
    blockedLoginStartTime: null,
    reachableUsers: [],
    reachableRoles: [],
    session: null,

    // COMPANY KNOWLEDGE
    focusKnowledge: null,
    knowledges: [],
  },
  getters,
  mutations,
  actions: {
    ...actions,
    ...Sockets.actions,
  },
  modules: {
    adminModule,
    kbStore: {
      namespaced: true,
      ...kbStore,
    },
    dashboard,
    knowledgeModule,
    kbExportModule,
    webModule,
    webKnowledgeModule,
    modalsModule,
    taskModule,
    websocketModule,
    publicKnowledgeModule,
    adminUserDirectoryModule,
    attachmentModule,
    threadModule,
    brainModule,
    academyModule,
  },
  plugins: [
    createPersistedState({
      key: 'mayday-vuex',
    }),
    dashboardTabDataFetchPlugin,
    employeesTableFetchPlugin,
    knowledgeFetchPlugin,
    websocketInitPlugin,
    publicKnowledgePlugin,
  ],
});
