import * as types from './mutation-types';
import Fuse from 'fuse.js';

export default {
  /**
   * COMPANY
   */

  // ADMIN VIEWS / SETTINGS
  adminKnowledges: (state) => state.adminKnowledges,
  privateAdminKnowledges: (state) =>
    state.adminKnowledges.filter((k) => !k.publicData.isPublic),
  focusAdminKnowledge: (state, getters) => {
    const { focusAdminKnowledgeId } = state;
    const { privateAdminKnowledges } = getters;
    return privateAdminKnowledges.find((k) => k.id === focusAdminKnowledgeId);
  },

  // EMPLOYEES
  employees(state) {
    const { employees } = state;
    employees.map((e) =>
      e.labels.sort(
        (a, b) => a.companyUserLabelCategoryId - b.companyUserLabelCategoryId,
      ),
    );
    return employees;
  },
  employeesCount: (state) => state.employeesCount,
  employeesTableFilters: (state) => state.employeesTableFilters,
  employeesTableIsLoading: (state) => state.employeesTableIsLoading,

  showModalEmployees: (state) => state.showModalEmployees,
  modalEmployees: (state) => state.modalEmployees,
  modalEmployeesCount: (state) => state.modalEmployeesCount,
  modalEmployeesTableFilters: (state) => state.modalEmployeesTableFilters,
  modalEmployeesTableIsLoading: (state) => state.modalEmployeesTableIsLoading,

  employeesLoadingMutationType: (state) =>
    state.showModalEmployees
      ? types.SET_MODAL_EMPLOYEES_TABLE_LOADING
      : types.SET_EMPLOYEES_TABLE_LOADING,
  employeesUsersMutationType: (state) =>
    state.showModalEmployees ? types.SET_MODAL_EMPLOYEES : types.SET_EMPLOYEES,
  employeesFocusTableFilters: (state) =>
    state.showModalEmployees
      ? state.modalEmployeesTableFilters
      : state.employeesTableFilters,

  pendingEmployees: (state) => state.pendingEmployees,
  pendingEmployeesCount: (state) => state.pendingEmployeesCount,
  pendingEmployeesTableFilters: (state) => state.pendingEmployeesTableFilters,
  pendingEmployeesTableIsLoading: (state) =>
    state.pendingEmployeesTableIsLoading,

  showModalPendingEmployees: (state) => state.showModalPendingEmployees,
  modalPendingEmployees: (state) => state.modalPendingEmployees,
  modalPendingEmployeesCount: (state) => state.modalPendingEmployeesCount,
  modalPendingEmployeesTableFilters: (state) =>
    state.modalPendingEmployeesTableFilters,
  modalPendingEmployeesTableIsLoading: (state) =>
    state.modalPendingEmployeesTableIsLoading,

  pendingEmployeesLoadingMutationType: (state) =>
    state.showModalPendingEmployees
      ? types.SET_MODAL_PENDING_EMPLOYEES_TABLE_LOADING
      : types.SET_PENDING_EMPLOYEES_TABLE_LOADING,
  pendingEmployeesUsersMutationType: (state) =>
    state.showModalPendingEmployees
      ? types.SET_MODAL_PENDING_EMPLOYEES
      : types.SET_PENDING_EMPLOYEES,
  pendingEmployeesFocusTableFilters: (state) =>
    state.showModalPendingEmployees
      ? state.modalPendingEmployeesTableFilters
      : state.pendingEmployeesTableFilters,

  hasPendingUsers: (state) => {
    if (!state.pendingEmployees) return null;
    if (state.pendingEmployees.length > 0) return true;
    if (state.pendingEmployeesTableFilters.filters.length > 0) return true;
    if (state.pendingEmployeesTableFilters.search.length > 0) return true;
    return false;
  },

  userInvitations: (state) => state.userInvitations,

  companyHostname: (state, _getters, _rootState, rootGetters) => {
    const focusKnowledgeId = rootGetters['knowledgeModule/focusKnowledgeId'];
    return (
      state.companyHostnames[focusKnowledgeId] ||
      state.companyHostnames['default']
    );
  },
  groups: (state) => state.groups,
  groupsIsLoading: (state) => state.groupsIsLoading,
  roles: (state) => state.roles,
  rolesIsLoading: (state) => state.rolesIsLoading,
  reviewers: (state) => state.reviewers,
  userLabelCategories: (state) => state.userLabelCategories,
  userLabelCategoriesIsLoading: (state) => state.userLabelCategoriesIsLoading,
  categorySettingsLookup: (state) => {
    if (!state.userLabelCategories) return {};
    return Object.fromEntries(
      state.userLabelCategories.map(({ id, icon, color }) => [
        id,
        { icon, color },
      ]),
    );
  },

  usersForAdministration: (state) => state.usersForAdministration,
  usersForAdministrationCount: (state) => state.usersForAdministrationCount,
  usersForAdministrationTableQueryParameter: (state) =>
    state.usersForAdministrationTableQueryParameter,
  usersForAdministrationTableFilters: (state) =>
    state.usersForAdministrationTableQueryParameter.filters,
  usersForAdministrationTableSearch: (state) =>
    state.usersForAdministrationTableQueryParameter.search,
  usersForAdministrationTableCurrentPage: (state) =>
    state.usersForAdministrationTableQueryParameter.currentPage,
  usersForAdministrationTableTotalPageCount: (state) =>
    state.usersForAdministrationTableQueryParameter.totalPageCount,
  usersForAdministrationTableIsLoading: (state) =>
    state.usersForAdministrationTableIsLoading,
  usersForAdministrationLoadingMutationType: () =>
    types.SET_USERS_FOR_ADMINISTRATION_TABLE_LOADING,
  usersForAdministrationMutationType: () =>
    types.SET_USERS_FOR_ADMINISTRATION_TABLE,

  exportUserLoading: (state) => state.userExportLoading.notPending,
  exportPendingUserLoading: (state) => state.userExportLoading.pending,
  companySynonyms: (state) => state.companySynonyms,
  contributors: (state) => state.contributors,
  allContributors: (state) => state.allContributors,
  searchContributors:
    (_state, getters) =>
    (query, limit = 5) => {
      if (!query) {
        const results = getters.contributors.filter(
          ({ username }) => !!username,
        );
        if (results.length > 5) {
          return results.splice(results.length - 5);
        }

        return results;
      }

      const finder = new Fuse(
        getters.contributors.filter(({ username }) => !!username),
        {
          isCaseSensitive: false,
          keys: ['username'],
          threshold: 0.35,
        },
      );

      return finder.search(query, { limit }).map(({ item }) => item);
    },
};
