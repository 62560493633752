<template>
  <div class="publish-modal">
    <modal :show.sync="localDisplay">
      <div class="row d-flex mt-3 justify-content-center">
        <div class="col-11">
          <div class="staged-version-modal-header">
            <font-awesome-icon
              class="icon"
              :icon="['fal', 'exclamation-circle']"
            />
            <div class="mt-3 font-weight-bold">
              {{ $t('knowledge.staged-version.modals.publish.title') }}
            </div>
            <div class="mt-2 publish-modal-description">
              {{ $t('knowledge.staged-version.modals.publish.description') }}
            </div>
          </div>
          <base-callout class="mt-3">
            <div>
              <div>
                {{
                  $tc(
                    'knowledge.staged-version.modals.publish.warning',
                    languages.length + 1,
                  )
                }}
              </div>
              <ul v-if="languages.length" class="mb-0 list-languages">
                <li v-for="lang in languages" :key="lang">
                  {{ $t(`knowledge.languages.${lang}`) }}
                </li>
              </ul>
            </div>
          </base-callout>
        </div>
      </div>
      <div slot="footer" class="w-100 d-flex flex-column">
        <el-button
          class="w-100"
          type="primary"
          size="mini"
          :disabled="fakeLoading"
          @click="userChoice(true)"
        >
          <font-awesome-icon
            v-if="fakeLoading"
            :icon="['far', 'spinner']"
            spin
          />
          <span v-else>{{
            $t('knowledge.staged-version.modals.publish.publish')
          }}</span>
        </el-button>

        <el-button
          class="w-100 button-cancel"
          type="secondary"
          size="mini"
          @click="userChoice(false)"
          >{{ $t('knowledge.staged-version.modals.publish.cancel') }}</el-button
        >
      </div>
    </modal>
  </div>
</template>
<script>
import Modal from '@/components/Modal';
import BaseCallout from '@/components/BaseCallout.vue';

export default {
  name: 'publish-staged-version-modal',
  props: {
    display: Boolean,
    languages: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Modal,
    BaseCallout,
  },
  data() {
    return {
      localDisplay: this.display,
      fakeLoading: false,
    };
  },
  methods: {
    userChoice(choice) {
      if (choice) {
        // fake delay to simulate wait of request response to avoid visual tilt
        this.fakeLoading = true;

        this.$emit('events', {
          eventName: 'choice',
          eventData: true,
          eventWait: 1000,
        });
      } else this.localDisplay = false;
    },
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.publish-modal {
  :deep() .modal-dialog {
    width: 320px;
  }

  :deep() .modal-content {
    border-radius: 8px !important;
  }

  :deep() .modal-body {
    padding: 8px 8px 0px 8px;
  }
}
.content-primary-text-container {
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  background-color: rgba($blue-mayday, 0.05);
}

.staged-version-modal-header {
  text-align: center;
  line-height: 18px;
}

.publish-modal-description {
  font-size: 14px;
}

.icon {
  color: $blue-mayday;
  width: 42px;
  height: 42px;
}

.w-divider {
  background-color: white;
}

.button-cancel {
  margin-top: 8px;
  margin-left: 0px !important;
}

.list-languages {
  padding-inline-start: 20px !important;
}
</style>
