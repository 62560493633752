<template>
  <modal class="content-verification-policy-modal" :show.sync="localDisplay">
    <div class="row d-flex mt-5 justify-content-center">
      <div class="col-11 text-center">
        <modal-header
          :style-name="{ backgroundColor: '#0081F91A' }"
          class="mb-3"
        >
          <div slot="icon" class="d-inline-flex">
            <font-awesome-icon
              class="icon blue-mayday"
              :icon="['fas', 'badge-check']"
            />
          </div>
          <div class="mt-3">
            <div>
              <p class="mb-0 font-weight-bold">
                {{ $t('components.modals.verification-policy.title') }}
              </p>
            </div>
            <el-divider class="my-3"></el-divider>
            <small class="text-primary">
              {{ $t('components.modals.verification-policy.description') }}
            </small>
          </div>
        </modal-header>

        <div class="flex-column d-flex justify-content-center subcontent">
          <p class="m-2">
            {{ $t('components.modals.verification-policy.action-title') }}
          </p>
          <div
            class="d-flex flex-row align-items-center justify-content-between m-2 verification-switch px-3 py-3"
          >
            <p class="text-left mb-0">
              {{ $t('components.modals.verification-policy.action') }}
            </p>
            <el-switch
              class="verification-switch-input my-3"
              v-model="switchValue"
            ></el-switch>
          </div>

          <div
            v-if="switchValue"
            class="container flex-column d-flex justify-content-center px-4"
          >
            <label
              class="label row text-left text-bold mb-1 pl-1"
              for="reviewer"
            >
              {{ $t('components.modals.verification-policy.reviewer.label') }}
              <el-tooltip
                v-if="displayErrorTooltip"
                placement="top"
                effect="light"
              >
                <template #content>
                  <p class="my-2 info-text">
                    {{
                      $t(
                        'components.modals.verification-policy.reviewer.error-tooltip',
                      )
                    }}
                  </p>
                </template>
                <i class="fa fa-info-circle info has-danger"></i>
              </el-tooltip>
            </label>
            <el-select
              v-model="model.reviewer"
              :placeholder="
                $t('components.modals.verification-policy.reviewer.placeholder')
              "
              class="selector row pb-4"
              value-key="id"
              @change="reviewerDangerClass = 'has-danger'"
              id="reviewer"
              filterable
              :no-match-text="
                $t(
                  'components.modals.verification-policy.reviewer.empty-placeholder',
                )
              "
            >
              <template slot="prefix">
                <i
                  :class="[
                    $v.model.reviewer.$invalid
                      ? reviewerDangerClass
                      : 'has-success',
                    'fa',
                    'fa-user',
                  ]"
                ></i>
              </template>
              <el-option-group
                v-for="reviewerOption in parsedReviewers"
                :key="reviewerOption.label"
                :label="
                  $t(
                    `components.modals.verification-policy.reviewer.group.${reviewerOption.label}`,
                  )
                "
              >
                <el-option
                  v-for="item in reviewerOption.options"
                  :key="item.value.label"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-option-group>
            </el-select>

            <label class="label row text-left text-bold mb-1 pl-1" for="period">
              {{ $t('components.modals.verification-policy.period.label') }}
            </label>
            <el-select
              v-model="model.period"
              :placeholder="
                $t('components.modals.verification-policy.period.placeholder')
              "
              class="row selector mb-4"
              @change="periodDangerClass = 'has-danger'"
              id="period"
            >
              <template slot="prefix" class="d-flex prefix">
                <i
                  :class="[
                    $v.model.period.$invalid
                      ? periodDangerClass
                      : 'has-success',
                    'fa',
                    'fa-sync',
                  ]"
                ></i>
              </template>
              <el-option
                v-for="period in periods"
                :key="period.value"
                :label="period.name"
                :value="period.value"
              ></el-option>
            </el-select>
            <label
              v-if="isPreciseDate"
              class="label row text-left text-bold mb-1 pl-1"
              for="precise-date"
            >
              {{
                $t('components.modals.verification-policy.precise-date.label')
              }}
            </label>
            <el-date-picker
              v-if="isPreciseDate"
              v-model="model.verificationDueDate"
              type="date"
              placeholder="Pick a day"
              :class="[
                'mb-3 row date-picker',
                this.model.verificationDueDate ? 'due-date-is-set' : '',
              ]"
              value-format="timestamp"
              :picker-options="datePickerOptions"
            >
            </el-date-picker>
            <div class="d-flex flex-row justify-content-between mb-2">
              <label
                class="label row text-left text-bold mb-1 pl-1"
                for="set-unpublish"
              >
                {{
                  $t(
                    'components.modals.verification-policy.set-unpublish.label',
                  )
                }}
              </label>
              <el-switch
                id="set-unpublish"
                class="verification-switch-input"
                v-model="model.setToDraftAtDueDate"
              ></el-switch>
            </div>
          </div>

          <ContentVerificationPolicyReview
            v-if="
              model.period &&
              model.reviewer.type &&
              ['create', 'update'].includes(submitAction)
            "
            :title="title"
            :model="model"
            :periods="periods"
            :username="username"
          />
        </div>
      </div>
    </div>
    <template slot="footer">
      <div class="container text-right">
        <div class="footer-row row d-flex col-12 px-0">
          <base-button-old
            v-if="submitAction !== 'disabled'"
            type="secondary"
            size="sm"
            @click="close"
          >
            {{ $t('components.modals.verification-policy.cancel') }}
          </base-button-old>
          <base-button-old
            :type="submitAction === 'delete' ? 'danger' : 'primary'"
            size="sm"
            class="ml-2"
            :disabled="submitAction === 'disabled'"
            @click="submit"
          >
            <span
              v-if="isLoading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span v-else>
              {{
                submitAction === 'delete'
                  ? $t('components.modals.verification-policy.submit.delete')
                  : $t('components.modals.verification-policy.submit.update')
              }}
            </span>
          </base-button-old>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ContentVerificationPolicyReview from './ContentVerificationPolicyReview';
import Modal from '@/components/Modal';
import ModalHeader from '@/components/Modals/ModalHeader';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'content-verification-policy-modal',

  props: {
    title: String,
    display: Boolean,
    reviewerOptions: Array,
    verificationPolicy: Object,
    username: String,
  },
  components: {
    ContentVerificationPolicyReview,
    Modal,
    ModalHeader,
  },
  data() {
    return {
      localDisplay: this.display,
      isLoading: false,
      switchValue: false,
      options: [],
      reviewerDangerClass: '',
      periodDangerClass: '',
      model: {
        reviewer: {},
        period: '',
        verificationDueDate: '',
        setToDraftAtDueDate: false,
      },
      datePickerOptions: {
        disabledDate(time) {
          const enrichedDate = new Date();
          return time.getTime() < enrichedDate - 86400000; // 24 hours
        },
      },
      periods: [
        {
          name: this.$t(
            'components.modals.verification-policy.period.name.every-day',
          ),
          value: 86400,
        },
        {
          name: this.$t(
            'components.modals.verification-policy.period.name.every-week',
          ),
          value: 604800,
        },
        {
          name: this.$t(
            'components.modals.verification-policy.period.name.every-month',
          ),
          value: 2629800,
        },
        {
          name: this.$t(
            'components.modals.verification-policy.period.name.every-three-months',
          ),
          value: 7889400,
        },
        {
          name: this.$t(
            'components.modals.verification-policy.period.name.every-six-months',
          ),
          value: 15778800,
        },
        {
          name: this.$t(
            'components.modals.verification-policy.period.name.every-year',
          ),
          value: 31557600,
        },
        {
          name: this.$t(
            'components.modals.verification-policy.period.name.precise-date',
          ),
          value: 0,
        },
      ],
    };
  },
  validations() {
    return {
      model: {
        reviewer: {
          required,
          isReviewerValid: this.isReviewerValid,
        },
        period: {
          required,
        },
        verificationDueDate: {},
      },
    };
  },
  async created() {
    await this.getCompanyReviewers();
    this.initModel();
  },
  methods: {
    ...mapActions('adminModule', ['getCompanyReviewers']),
    isReviewerValid(reviewer) {
      if (Object.keys(reviewer).length) {
        return this.reviewers[reviewer.type].filter((r) => r.id === reviewer.id)
          .length;
      }
      return false;
    },
    initModel() {
      if (
        this.verificationPolicy.verificationRange ||
        this.verificationPolicy.verificationRange === 0
      ) {
        this.switchValue = true;
        const getValueFromOptions = (type, idKey) =>
          this.parsedReviewers
            .find((group) => group.label === type)
            .options.find(
              (option) => option.value.id === this.verificationPolicy[idKey],
            ).value;
        this.model.reviewer =
          this.verificationPolicy.userId &&
          this.verificationPolicy.userId !== 'null'
            ? getValueFromOptions('user', 'userId')
            : getValueFromOptions('role', 'customRoleId');
        this.model.reviewer.type =
          this.verificationPolicy.userId &&
          this.verificationPolicy.userId !== 'null'
            ? 'users'
            : 'roles';
        this.model.period = this.verificationPolicy.verificationRange;
        this.model.verificationDueDate =
          this.verificationPolicy.verificationDueDate;
        if (this.verificationPolicy.setToDraftAtDueDate)
          this.model.setToDraftAtDueDate = true;
      }
    },
    submit() {
      this.isLoading = true;
      this.$emit('events', {
        eventName: `${this.submitAction}-verification-policy`,
        eventData: this.payload,
      });
    },
    close() {
      this.initModel();
      this.localDisplay = false;
    },
  },
  computed: {
    ...mapGetters('adminModule', ['reviewers']),
    parsedReviewers() {
      return [
        {
          label: 'user',
          options: this.reviewers.users.map((user) => ({
            label: user.username,
            value: {
              id: user.id,
              label: user.username,
              type: 'users',
            },
          })),
        },
        {
          label: 'role',
          options: this.reviewers.roles.map((role) => ({
            label: role.name,
            value: {
              id: role.id,
              label: role.name,
              type: 'roles',
            },
          })),
        },
      ];
    },
    submitAction() {
      if (
        !this.verificationPolicy.verificationRange &&
        this.switchValue &&
        !this.$v.model.$invalid
      ) {
        return 'create';
      }
      if (
        (this.verificationPolicy.verificationRange ||
          this.verificationPolicy.verificationRange === 0) &&
        !this.switchValue
      ) {
        return 'delete';
      }
      if (
        this.verificationPolicy.verificationRange &&
        this.switchValue &&
        !this.$v.model.$invalid
      ) {
        return 'update';
      }
      return 'disabled';
    },
    displayErrorTooltip() {
      return (
        !this.$v.model.reviewer.isReviewerValid && this.model.reviewer === {}
      );
    },
    payload() {
      let payload = {};
      if (Object.keys(this.model.reviewer).includes('type')) {
        if (this.model.reviewer.type === 'users') {
          payload.userId = this.model.reviewer.id;
          payload.customRoleId = null;
        } else {
          payload.customRoleId = this.model.reviewer.id;
          payload.userId = null;
        }
        payload.verificationRange = this.model.period;
        payload.setToDraftAtDueDate = this.model.setToDraftAtDueDate;
        if (this.model.period === 0) {
          payload.verificationDueDate = String(this.model.verificationDueDate);
        }
      }
      return payload;
    },
    isPreciseDate() {
      return this.model.period === 0;
    },
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) {
        this.$emit('events', {
          eventName: 'close',
          eventData: null,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.prefix {
  align-items: center;
}

.verification-switch {
  background-color: $grey-1-mayday;
  border-radius: 4px;
  border: 1px solid $grey-4-mayday;
}

.label {
  width: 100%;
  font-size: 0.9em;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.has-success:after,
.has-danger:after {
  display: none;
}

.has-success {
  color: rgba($blue-mayday, 0.4);
}

.has-danger {
  color: rgba($red-mayday, 0.1);
}

.info {
  font-size: 0.7em;
  margin-left: 0.5em;
  cursor: pointer;
  color: $red-mayday;
}

.info-text {
  color: $grey-9-mayday;
}

.footer-row {
  justify-content: flex-end;
}

.date-picker {
  width: 108%;
}

.due-date-is-set {
  &:deep() .el-input__prefix {
    color: rgba($blue-mayday, 0.4);
  }
}
</style>
<style lang="scss" scoped>
.content-verification-policy-modal {
  .modal-title {
    width: 100%;
    border-bottom: 1px solid $grey-4-mayday;
  }

  :deep() .modal-body {
    max-height: 80vh;
    overflow: auto;
    .selector {
      .el-input__prefix {
        display: flex;
        align-items: center;
        margin-left: 6px;
      }
    }
  }

  .modal-footer {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  :deep() .verification-switch-input.is-checked :deep() .el-switch__core {
    background-color: $blue-mayday;
    &:after {
      color: $blue-mayday;
    }
  }

  :deep() .el-switch__core:after {
    // content: '|';
    line-height: 1.2em;
    color: $grey-5-mayday;
    font-weight: bold;
    font-size: 0.8em;
    width: 14px;
    height: 14px;
    top: 2px;
  }
}

.blue-mayday {
  color: $blue-mayday;
}

.icon {
  width: 24px;
  height: 24px;
}

.subcontent {
  border: 1px solid $blue-mayday;
  border-radius: 2px;
}
</style>
