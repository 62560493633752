<template>
  <base-dropdown-options
    :options="optionsAsString"
    :icon="icon"
    :trigger-text="triggerText"
    :disabled="disabled"
    :icon-only="iconOnly"
    :variant="variant"
    @select="$emit('select', $event)"
  ></base-dropdown-options>
</template>
<script>
export default {
  name: 'BaseDropdownOptionsWrapper',
  props: {
    options: [],
    isHorizontal: { type: Boolean, default: false },
    triggerText: String,
    disabled: Boolean,
    iconOnly: Boolean,
    variant: String,
  },
  computed: {
    optionsAsString() {
      return JSON.stringify(this.options);
    },
    icon() {
      return this.isHorizontal ? 'ellipsis-h' : 'ellipsis-v';
    },
  },
};
</script>
