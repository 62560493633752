<template>
  <modal
    :show.sync="localDisplay"
    :show-close="false"
    class="delete-case-modal"
  >
    <modal-header :style-name="{ backgroundColor: '#fd00440D' }" class="mb-3">
      <div slot="icon" class="d-inline-flex">
        <font-awesome-icon class="icon" :icon="['fas', 'times']" />
      </div>
      <div>
        <p class="mt-3 mb-0">
          {{
            $t(
              'settings.card-settings-options.case-parameters.modals.danger-delete.header-label',
            )
          }}
          {{ caseLabel }}
        </p>
      </div>
      <div slot="footer" v-if="relatedContentsCount">
        <small class="header-sublabel">
          {{ relatedContentsCount }}
          {{
            $t(
              'settings.card-settings-options.case-parameters.modals.danger-delete.header-sublabel',
            )
          }}
        </small>
      </div>
    </modal-header>
    <div class="delete-container">
      <p class="mb-2">
        {{
          $t(
            'settings.card-settings-options.case-parameters.modals.danger-delete.danger-zone-title',
          )
        }}
      </p>
      <small class="text-muted">{{ dangerZoneWarning }}</small>
      <base-input
        id="validationInput"
        v-model="validationInput"
        :placeholder="`DELETE-${this.caseLabel.toUpperCase()}`"
        type="text"
        class="col-10 mx-auto mt-4 mb-2"
      >
      </base-input>
    </div>
    <div slot="footer">
      <base-button-old
        class="mx-auto"
        type="danger"
        @click.prevent="deleteCase()"
        :disabled="!validate"
        >Confirmer</base-button-old
      >
    </div>
  </modal>
</template>

<script>
import ModalHeader from '@/components/Modals/ModalHeader';

export default {
  name: 'danger-delete-case-modal',
  components: {
    ModalHeader,
  },
  props: {
    display: Boolean,
    relatedContentsCount: {
      type: Number,
      required: false,
    },
    caseId: String,
    caseLabel: String,
  },
  data() {
    return {
      localDisplay: this.display,
      validationInput: '',
    };
  },
  computed: {
    validate() {
      return (
        this.validationInput === `DELETE-${this.caseLabel.toUpperCase()}`.trim()
      );
    },
    dangerZoneWarning() {
      return `${this.$t(
        'settings.card-settings-options.case-parameters.modals.danger-delete.danger-zone-warning-part-1',
      )} DELETE-${this.caseLabel.toUpperCase()} ${this.$t(
        'settings.card-settings-options.case-parameters.modals.danger-delete.danger-zone-warning-part-2',
      )}`;
    },
  },
  methods: {
    deleteCase() {
      this.$emit('events', {
        eventName: 'delete-case',
      });
      this.localOpen = false;
    },
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  width: 32px;
  height: 32px;
  color: $grey-5-mayday;
}
.header-sublabel {
  color: $red-mayday;
}
.delete-container {
  margin-top: 24px;
  padding: 16px;
  border: 1px solid $red-mayday;
  border-radius: 4px;
  text-align: center;
}
.delete-case-modal {
  :deep() .modal-body {
    max-height: 65vh;
    overflow: auto;
  }
  :deep() .modal-footer {
    justify-content: center;
    padding-top: 0;
  }
}
</style>
