import data from './export.data.raw.js';

export class ExportServiceData {
  constructor(axiosClient, academyGql) {
    this.axiosClient = axiosClient;
    this.academyGql = academyGql;
  }

  async putKBOverviewExport({ knowledgeId }) {
    return await this.axiosClient.put(`/knowledge/export/${knowledgeId}`);
  }

  async getKBOverviewExport({ knowledgeId }) {
    return await this.axiosClient.get(`/knowledge/export/${knowledgeId}`);
  }

  async putKBContentsExport({ knowledgeId }) {
    return await this.axiosClient.put(`/contents/export/${knowledgeId}`);
  }

  async getKBContentsExport({ knowledgeId }) {
    return await this.axiosClient.get(`/contents/export/${knowledgeId}`);
  }

  async getExportsPredefinedFilters(exportType) {
    const response = await this.academyGql.query({
      query: data.exportsPredefinedFilters,
      fetchPolicy: 'no-cache',
      variables: { exportType },
    });

    return response.data.exportsPredefinedFilters;
  }

  async getExportGlobalAttempts({ predefinedFilterId }) {
    const response = await this.academyGql.query({
      query: data.exportGlobalAttempts,
      fetchPolicy: 'no-cache',
      variables: { predefinedFilterId },
    });

    return response.data.exportGlobalAttempts;
  }
}
