import data from './task.data.raw';

export class TaskServiceData {
  constructor(client) {
    this.client = client;
  }

  getBacklogTasksCount() {
    return this.client
      .query({ query: data.getBacklogTasksCount, fetchPolicy: 'no-cache' })
      .then((response) => response.data.backlogTasksCount)
      .catch((error) => error);
  }
}
