<template>
  <div class="delete-modal">
    <modal :show.sync="localDisplay">
      <div class="row d-flex mt-3 justify-content-center">
        <div class="col-11">
          <div class="staged-version-modal-header">
            <font-awesome-icon
              class="icon red-mayday"
              :icon="['fal', 'exclamation-circle']"
            />
            <div class="mt-3 font-weight-bold">
              {{ $t('knowledge.staged-version.modals.delete.title') }}
            </div>
            <div class="mt-2 delete-modal-description">
              {{ $t('knowledge.staged-version.modals.delete.description') }}
            </div>
          </div>
          <base-callout class="mt-3" type="danger">
            <div class="text-left">
              {{ $t('knowledge.staged-version.modals.delete.warning') }}
            </div>
          </base-callout>
        </div>
      </div>
      <div slot="footer" class="w-100 d-flex flex-column">
        <el-button
          class="w-100"
          type="danger"
          size="mini"
          :disabled="fakeLoading"
          @click="userChoice(true)"
        >
          <font-awesome-icon
            v-if="fakeLoading"
            :icon="['far', 'spinner']"
            spin
          />
          <span v-else>{{
            $t('components.modals.delete-modal.body.delete')
          }}</span>
        </el-button>

        <el-button
          class="w-100 button-cancel"
          type="secondary"
          size="mini"
          @click="userChoice(false)"
          >{{ $t('components.modals.delete-modal.body.cancel') }}</el-button
        >
      </div>
    </modal>
  </div>
</template>
<script>
import Modal from '@/components/Modal';
import BaseCallout from '@/components/BaseCallout.vue';

export default {
  name: 'delete-staged-version-modal',
  props: {
    display: Boolean,
  },
  components: {
    Modal,
    BaseCallout,
  },
  data() {
    return {
      localDisplay: this.display,
      fakeLoading: false,
    };
  },
  methods: {
    userChoice(choice) {
      if (choice) {
        // fake delay to simulate wait of request response to avoid visual tilt
        this.fakeLoading = true;

        this.$emit('events', {
          eventName: 'choice',
          eventData: true,
          eventWait: 1000,
        });
      } else this.localDisplay = false;
    },
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.delete-modal {
  :deep() .modal-dialog {
    width: 320px;
  }

  :deep() .modal-content {
    border-radius: 8px !important;
  }

  :deep() .modal-body {
    padding: 8px 8px 0px 8px;
  }
}
.content-primary-text-container {
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 4px;
  background-color: rgba($blue-mayday, 0.05);
}

.red-mayday {
  color: $red-mayday;
}

.staged-version-modal-header {
  text-align: center;
  line-height: 18px;
}

.delete-modal-description {
  font-size: 14px;
}

.header-sublabel {
  color: $red-mayday;
}

.icon {
  width: 42px;
  height: 42px;
}

.button-cancel {
  margin-top: 8px;
  margin-left: 0px !important;
}
</style>
