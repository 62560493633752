// ADMIN services
import { CompanyService, CompanyServiceData } from './admin/company';
import {
  CompanyAttributeService,
  CompanyAttributeServiceData,
} from './admin/companyAttribute';
import {
  CompanySynonymService,
  CompanySynonymServiceData,
} from './admin/companySynonym';
import {
  CompanyGroupService,
  CompanyGroupServiceData,
} from './admin/companyGroup';
import { UserService, UserServiceData } from './admin/user';
import { ExportUsers } from './admin/user/exportUsers';
import { UserLabelService, UserLabelServiceData } from './admin/userLabel';
import { AutomationService, AutomationServiceData } from './admin/automation';
import { ZendeskService, ZendeskServiceData } from './admin/zendesk';
import {
  IntegrationService,
  IntegrationServiceData,
} from './admin/integration';
import { SettingService, SettingServiceData } from './admin';
import { TaskService, TaskServiceData } from './admin/task';
import { CustomRoleService, CustomRoleServiceData } from './admin/customRole';

// KB services
import { ContentService, ContentServiceData } from './kb/content';

// Company Public Config
import {
  CompanyPublicConfigService,
  CompanyPublicConfigServiceData,
} from './admin/companyPublicConfig';

// Dashboard
import {
  DashboardFilterService,
  DashboardFilterServiceData,
} from './dashboard/filter';
import {
  ContentAnalyticService,
  ContentAnalyticServiceData,
} from './dashboard/contentAnalytic';
import {
  DownloadAnalyticsService,
  DownloadAnalyticsServiceData,
} from './dashboard/downloadAnalytics';
import {
  AskAnalyticService,
  AskAnalyticServiceData,
} from './dashboard/askAnalytic';
import {
  WorkflowAnalyticService,
  WorkflowAnalyticServiceData,
} from './dashboard/workflowAnalytic';

// Parametric services
import { ProductService, ProductServiceData } from './parametric/product';
import {
  ProductNotificationService,
  ProductNotificationServiceData,
} from './parametric/productNotifications';
import { CaseService, CaseServiceData } from './parametric/case';
import { HierarchyService, HierarchyServiceData } from './parametric/hierarchy';
import {
  ParametricContentService,
  ParametricContentServiceData,
} from './parametric/content';
import {
  ContentParameterLabelService,
  ContentParameterLabelServiceData,
} from './parametric/contentParameter';
import {
  ContentParameterCategoryService,
  ContentParameterCategoryServiceData,
} from './parametric/contentParameter';
import {
  ContentTemplateService,
  ContentTemplateServiceData,
} from './parametric/contentTemplate';
import {
  ContentThreadService,
  ContentThreadServiceData,
} from './parametric/contentThread';
import {
  ContextSessionService,
  ContextSessionServiceData,
} from './parametric/contextSession';
import {
  ContextVariableService,
  ContextVariableServiceData,
} from './parametric/contextVariable';
import {
  ContentEventService,
  ProductEventService,
  GeneralEventService,
  CompanyEventService,
  TaskEventService,
  // NotificationEventService,
  ThreadEventService,
  PublicSettingsEventService,
  AskEventService,
  VersionEventService,
  AiEditorAssistEventService,
} from './parametric/event';
import { SearchService, SearchServiceData } from './parametric/search';
import { ArchiveService, ArchiveServiceData } from './parametric/archive';
import { CloneService, CloneServiceData } from './parametric/clone';
import {
  ContentVersionService,
  ContentVersionServiceData,
} from './parametric/contentVersion';
import {
  ContentHistoryService,
  ContentHistoryServiceData,
} from './parametric/contentHistory';
import {
  AutomationRecollectionService,
  AutomationRecollectionServiceData,
} from './parametric/automation';
import { PluginService, PluginServiceData } from './parametric/plugin';
import { ResourceService, ResourceServiceData } from './parametric/resource';
import { AuthService, AuthServiceData } from './auth';

// User Directory
import {
  UserDirectoryService,
  UserDirectoryServiceData,
} from './kb/userDirectory';
import { BrainService, BrainServiceData } from './brain';
import { ExportService, ExportServiceData } from './export';

// Academy
import {
  ModuleLabelService,
  ModuleLabelServiceData,
} from './academy/moduleLabel';
import { WebsocketsService } from '@/services/admin/websockets/websockets';
import { WebsocketsServiceData } from '@/services/admin/websockets';

export const provider = (
  gql,
  mongoGql,
  parametricAxios,
  analyticsClient,
  apiAxios,
  logClient,
  authClient,
  brainClient,
  exportClient,
  brainFetchClient,
  academyGql,
) => ({
  // auth providers
  auth: new AuthService(new AuthServiceData(authClient)),
  // admin providers
  companies: new CompanyService(new CompanyServiceData(gql)),
  companyAttributes: new CompanyAttributeService(
    new CompanyAttributeServiceData(gql),
  ),
  companySynonyms: new CompanySynonymService(
    new CompanySynonymServiceData(gql),
  ),
  companyGroups: new CompanyGroupService(new CompanyGroupServiceData(gql)),
  integrations: new IntegrationService(
    new IntegrationServiceData(gql, apiAxios),
  ),
  users: new UserService(new UserServiceData(gql, apiAxios)),
  exportUsers: new ExportUsers(apiAxios),
  tasks: new TaskService(new TaskServiceData(gql)),
  customRoles: new CustomRoleService(new CustomRoleServiceData(gql)),
  settings: new SettingService(new SettingServiceData(apiAxios)),
  // kb providers
  automations: new AutomationService(new AutomationServiceData(gql)),
  contents: new ContentService(new ContentServiceData(gql)),
  //Company Public config providers
  companyPublicConfig: new CompanyPublicConfigService(
    new CompanyPublicConfigServiceData(gql),
  ), //

  // dashboard providers
  dashboardFilters: new DashboardFilterService(
    new DashboardFilterServiceData(gql),
  ),
  contentAnalytics: new ContentAnalyticService(
    new ContentAnalyticServiceData(analyticsClient, apiAxios),
  ),
  askAnalytics: new AskAnalyticService(
    new AskAnalyticServiceData(analyticsClient, apiAxios),
  ),
  workflowAnalytics: new WorkflowAnalyticService(
    new WorkflowAnalyticServiceData(analyticsClient, apiAxios),
  ),
  downloadAnalytics: new DownloadAnalyticsService(
    new DownloadAnalyticsServiceData(analyticsClient),
  ),
  // parametric providers
  products: new ProductService(
    new ProductServiceData(mongoGql, parametricAxios),
  ),
  productNotifications: new ProductNotificationService(
    new ProductNotificationServiceData(mongoGql),
  ),
  automationRecollection: new AutomationRecollectionService(
    new AutomationRecollectionServiceData(apiAxios),
  ),
  resources: new ResourceService(new ResourceServiceData(mongoGql)),
  contextSessions: new ContextSessionService(
    new ContextSessionServiceData(parametricAxios),
  ),
  contextVariables: new ContextVariableService(
    new ContextVariableServiceData(parametricAxios),
  ),
  // userLabels providers
  userLabels: new UserLabelService(new UserLabelServiceData(gql)),
  hierarchies: new HierarchyService(new HierarchyServiceData(mongoGql)),
  cases: new CaseService(new CaseServiceData(mongoGql)),
  parametricContents: new ParametricContentService(
    new ParametricContentServiceData(mongoGql, parametricAxios),
  ),
  contentParameterLabels: new ContentParameterLabelService(
    new ContentParameterLabelServiceData(mongoGql),
  ),
  contentParameterCategories: new ContentParameterCategoryService(
    new ContentParameterCategoryServiceData(mongoGql),
  ),
  events: {
    content: new ContentEventService(logClient),
    product: new ProductEventService(logClient),
    company: new CompanyEventService(logClient),
    general: new GeneralEventService(logClient),
    task: new TaskEventService(logClient),
    // notification: new NotificationEventService(logClient),
    thread: new ThreadEventService(logClient),
    publicSettings: new PublicSettingsEventService(logClient),
    ask: new AskEventService(logClient),
    version: new VersionEventService(logClient),
    aiEditorAssist: new AiEditorAssistEventService(logClient),
  },
  search: new SearchService(new SearchServiceData(mongoGql)),
  archives: new ArchiveService(new ArchiveServiceData(mongoGql)),
  clone: new CloneService(new CloneServiceData(mongoGql)),
  contentVersions: new ContentVersionService(
    new ContentVersionServiceData(mongoGql),
  ),
  contentHistory: new ContentHistoryService(
    new ContentHistoryServiceData(mongoGql),
  ),
  contentTemplates: new ContentTemplateService(
    new ContentTemplateServiceData(mongoGql),
  ),
  contentThreads: new ContentThreadService(
    new ContentThreadServiceData(mongoGql),
  ),
  plugins: new PluginService(new PluginServiceData(mongoGql)),
  zendesk: new ZendeskService(new ZendeskServiceData(apiAxios)),
  userDirectory: new UserDirectoryService(
    new UserDirectoryServiceData(mongoGql),
  ),
  brainClient: new BrainService(
    new BrainServiceData(brainClient, brainFetchClient),
  ),
  exportClient: new ExportService(
    new ExportServiceData(exportClient, academyGql),
  ),
  academy: {
    moduleLabels: new ModuleLabelService(
      new ModuleLabelServiceData(academyGql),
    ),
  },
  websockets: new WebsocketsService(new WebsocketsServiceData(gql, apiAxios)),
  onLogout: async () => {
    await gql.cache.reset();
    await mongoGql.cache.reset();
  },
  gql,
});
