import { handleCancelService } from '@/adapters/axiosClient';

export class ContentAnalyticServiceData {
  constructor(analyticsClient, serverClient) {
    this.analyticsClient = analyticsClient;
    this.serverClient = serverClient;
    this.handleCancelService = handleCancelService;
    // contents tab
    this.contentsLineChartCancelService = null;
    this.contentsStatsCancelService = null;
    this.contentsPublicStatsCancelService = null;
    this.contentsSearchStatsCancelService = null;
    this.contentTableCancelService = null;
    // content modal
    this.contentStatsCancelService = null;
    this.contentLineChartCancelService = null;
    this.contentDiagnosticChartCancelService = null;
    // usages tab
    this.usagesStatsCancelService = null;
    this.usagesAdoptionRateCancelService = null;
    this.usageTableCancelService = null;
    // usage modal
    this.usageLineChartCancelService = null;
    this.usageModalTableCancelService = null;
  }

  /**
   * READ METHODS
   */

  getContentsLineChart(params) {
    this.contentsLineChartCancelService = this.handleCancelService(
      this.contentsLineChartCancelService,
    );
    return this.analyticsClient
      .get('/content-monitoring/overview', {
        params,
        cancelToken: this.contentsLineChartCancelService.token,
      })
      .then((res) => res.data);
  }

  getKnowledgeById(knowledgeId) {
    this.contentsStatsCancelService = this.handleCancelService(
      this.contentsStatsCancelService,
    );
    return this.analyticsClient
      .get(`/content-monitoring/knowledge/${knowledgeId}`, {
        cancelToken: this.contentsStatsCancelService.token,
        headers: { 'Cache-Control': 'no-cache' },
      })
      .then((res) => res.data);
  }

  async getPublicStatCards(knowledgeId, params) {
    this.contentsPublicStatsCancelService = this.handleCancelService(
      this.contentsPublicStatsCancelService,
    );
    return this.analyticsClient
      .get(`/content-monitoring/public-statistics/${knowledgeId}`, {
        params,
        cancelToken: this.contentsPublicStatsCancelService.token,
      })
      .then((res) => res.data);
  }

  getTableData(params) {
    this.contentTableCancelService = this.handleCancelService(
      this.contentTableCancelService,
    );

    return this.analyticsClient
      .get('/content-monitoring', {
        params,
        cancelToken: this.contentTableCancelService.token,
      })
      .then((res) => res.data);
  }

  getSearchStatistics(params) {
    this.contentsSearchStatsCancelService = this.handleCancelService(
      this.contentsSearchStatsCancelService,
    );

    return this.analyticsClient
      .get('/search-event', {
        params,
        cancelToken: this.contentsSearchStatsCancelService.token,
      })
      .then((res) => res.data);
  }

  exportSearchStatistics(params, isMissed) {
    const currentDate = new Date();
    return this.analyticsClient
      .get('/search-event/export', {
        responseType: 'blob',
        params,
      })
      .then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute(
          'download',
          `${currentDate.getFullYear()}_${
            currentDate.getMonth() + 1
          }_${currentDate.getDate()}_${
            isMissed ? 'mayday-fail-search' : 'mayday-success-search'
          }.xlsx`,
        );
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  }

  getContent(id, params) {
    this.contentStatsCancelService = this.handleCancelService(
      this.contentStatsCancelService,
    );
    return this.analyticsClient
      .get(`/content-monitoring/${id}`, {
        params,
        cancelToken: this.contentStatsCancelService.token,
      })
      .then((res) => res.data);
  }

  getContentLineChart(id, params) {
    this.contentLineChartCancelService = this.handleCancelService(
      this.contentLineChartCancelService,
    );
    return this.analyticsClient
      .get(`/content-monitoring/overview/${id}`, {
        params,
        cancelToken: this.contentLineChartCancelService.token,
      })
      .then((res) => res.data);
  }

  getContentDiagnosticChart(id, params) {
    this.contentDiagnosticChartCancelService = this.handleCancelService(
      this.contentDiagnosticChartCancelService,
    );
    return this.analyticsClient
      .get(`/content-monitoring/diagnostic/${id}`, {
        params,
        cancelToken: this.contentDiagnosticChartCancelService.token,
      })
      .then((res) => res.data);
  }

  getContentTableFile(params) {
    return this.analyticsClient
      .get('/content-monitoring/download', {
        params,
        responseType: 'blob',
      })
      .then((res) => res.data);
  }

  // USAGE
  getUsageGlobalStats(params) {
    this.usagesStatsCancelService = this.handleCancelService(
      this.usagesStatsCancelService,
    );
    return this.analyticsClient
      .get('/user-monitoring/statistics', {
        params,
        cancelToken: this.usagesStatsCancelService.token,
      })
      .then((res) => res.data);
  }

  getAdoptionRateData(params) {
    this.usagesAdoptionRateCancelService = this.handleCancelService(
      this.usagesAdoptionRateCancelService,
    );
    return this.analyticsClient
      .get('/user-monitoring/adoption-rate', {
        params,
        cancelToken: this.usagesAdoptionRateCancelService.token,
      })
      .then((res) => res.data);
  }

  getUsageTableData(params) {
    this.usageTableCancelService = this.handleCancelService(
      this.usageTableCancelService,
    );
    return this.analyticsClient
      .get('/user-monitoring', {
        params,
        cancelToken: this.usageTableCancelService.token,
      })
      .then((res) => res.data);
  }

  getUsageTableFile(params) {
    return this.analyticsClient
      .get('/user-monitoring/download', {
        params,
        responseType: 'blob',
      })
      .then((res) => res.data);
  }

  // USAGE MODAL
  getUsageForUser(id, params) {
    this.usageStatsCancelService = this.handleCancelService(
      this.usageStatsCancelService,
    );
    return this.analyticsClient
      .get(`/user-monitoring/${id}`, {
        params,
        cancelToken: this.usageStatsCancelService.token,
      })
      .then((res) => res.data);
  }

  getUsageModalTableDataForUser(id, params) {
    this.usageModalTableCancelService = this.handleCancelService(
      this.usageModalTableCancelService,
    );
    return this.analyticsClient
      .get(`/content-monitoring/user/${id}`, {
        params,
        cancelToken: this.usageModalTableCancelService.token,
      })
      .then((res) => res.data);
  }

  getUsageLineChartForUser(id, params) {
    this.usageLineChartCancelService = this.handleCancelService(
      this.usageLineChartCancelService,
    );
    return this.analyticsClient
      .get(`/user-monitoring/overview/${id}`, {
        params,
        cancelToken: this.usageLineChartCancelService.token,
      })
      .then((res) => res.data);
  }
}
