import * as types from './mutation-types';
import { fetchEmployeesData } from './utils';
import cloneDeep from 'lodash.clonedeep';

export default {
  /**
   * GENERAL
   */
  logout({ commit }) {
    commit(types.LOGOUT);
  },
  resetUsersSettingsState({ commit }) {
    commit(types.LOGOUT);
  },

  /**
   * THIS SHOULD NEVER BE USED IN KNOWLEDGE NAVIGATION, FOR SETTINGS/ADMINS VIEWS
   */
  async getSettingsKnowledges({ commit }) {
    try {
      const knowledges =
        await this.$services.hierarchies.getSettingsKnowledges();
      commit(types.SET_ADMIN_KNOWLEDGES, knowledges);
      return knowledges;
    } catch (e) {
      commit(types.SET_ADMIN_KNOWLEDGES, []);
      return [];
    }
  },

  async addKnowledge({ commit, getters, dispatch }, { label, lang, groups }) {
    const knowledgesCopy = [...getters.adminKnowledges];

    const newHierarchy = await this.$services.hierarchies.add(
      label,
      lang,
      groups,
    );

    knowledgesCopy.push(newHierarchy);
    commit(types.SET_ADMIN_KNOWLEDGES, knowledgesCopy);
    dispatch('knowledgeModule/getKnowledges', null, { root: true });
    return newHierarchy;
  },

  async updateKnowledgeLabel(
    { commit, getters, dispatch },
    { id, newLabel, lang },
  ) {
    const { adminKnowledges } = getters;

    const updatedKnowledge = await this.$services.hierarchies.updateLabel(
      id,
      newLabel,
      lang,
    );

    const copy = [...adminKnowledges];

    const index = copy.findIndex((knowledge) => knowledge.id === id);
    if (index === -1) return;

    if (!lang || lang === updatedKnowledge.defaultLanguage) {
      copy[index].label = newLabel;
    }

    copy[index].labelTranslations = updatedKnowledge.labelTranslations;

    dispatch(
      'knowledgeModule/editKnowledgeLabel',
      { id, newLabel, labelTranslations: updatedKnowledge.labelTranslations },
      { root: true },
    );
    commit(types.SET_ADMIN_KNOWLEDGES, copy);
  },

  async updateKnowledgeIsDefault({ commit, getters }, { id }) {
    const { adminKnowledges } = getters;
    try {
      await this.$services.hierarchies.updateIsDefault(id);

      const copy = adminKnowledges.map((knowledgeDetails) => {
        knowledgeDetails.isDefault = knowledgeDetails.id == id;
        return knowledgeDetails;
      });

      commit(types.SET_ADMIN_KNOWLEDGES, copy);
    } catch (e) {
      return e;
    }
  },

  async updateKnowledgeIcon(
    { commit, getters },
    { id, iconType, value, color },
  ) {
    const { adminKnowledges } = getters;
    try {
      const { icon } = await this.$services.hierarchies.updateIcon(
        id,
        iconType,
        value,
        color,
      );

      const copy = [...adminKnowledges];

      const index = copy.findIndex((knowledge) => knowledge.id === id);

      if (index === -1) return;

      copy[index].icon = icon;

      commit(types.SET_ADMIN_KNOWLEDGES, copy);
    } catch (e) {
      return e;
    }
  },

  async updateKnowledgeIsDeleted({ commit, getters }, { id }) {
    const { adminKnowledges } = getters;
    try {
      await this.$services.hierarchies.updateIsDeleted(id);

      const copy = [...adminKnowledges];

      const index = copy.findIndex((knowledge) => knowledge.id === id);

      if (index === -1) return;

      copy[index].isDeleted = true;
      commit(types.SET_ADMIN_KNOWLEDGES, copy);
    } catch (e) {
      return e;
    }
  },

  async setFocusAdminKnowledgeId({ commit }, knowledgeId) {
    commit(types.SET_FOCUS_ADMIN_KNOWLEDGE_ID, knowledgeId);
  },

  async addLanguage({ commit, dispatch }, { id, lang }) {
    try {
      const updatedKnowledge = await this.$services.hierarchies.addLanguage(
        id,
        lang,
      );
      commit(types.ADD_LANGUAGE, updatedKnowledge);
      dispatch(
        'knowledgeModule/addNewLanguageToKnowledge',
        { id, lang, labelTranslations: updatedKnowledge.labelTranslations },
        {
          root: true,
        },
      );
    } catch (error) {
      return error;
    }
  },

  /**
   * COMPANY
   */

  async getCompanyDetailsLoading({ commit }, value) {
    commit(types.SET_EMPLOYEES_TABLE_LOADING, value);
    commit(types.SET_PENDING_EMPLOYEES_TABLE_LOADING, value);
    commit(types.SET_COMPANY_USER_LABEL_CATEGORIES_LOADING, value);
    commit(types.SET_GROUPS_LOADING, value);
    commit(types.SET_ROLES_LOADING, value);
  },

  async getCompanyDetails({ commit, state, dispatch }) {
    const { employeesTableFilters } = state;
    try {
      dispatch('getCompanyDetailsLoading', true);
      const res = await this.$services.companies.getDetails(
        employeesTableFilters,
        {
          skipEmployees: false,
          skipPendingEmployees: false,
          skipGroups: false,
          skipRoles: false,
          skipLabels: false,
        },
      );

      if (typeof res !== 'object' || !res) throw new Error('INTERNAL_ERROR');

      const {
        userGroups: groups,
        roles,
        employees,
        pendingEmployees,
        companyUserLabelCategories,
      } = res;

      commit(types.SET_EMPLOYEES, employees);
      commit(types.SET_PENDING_EMPLOYEES, pendingEmployees);
      commit(types.SET_GROUPS, { groups });
      commit(types.SET_ROLES, { roles });
      commit(
        types.SET_COMPANY_USER_LABEL_CATEGORIES,
        companyUserLabelCategories,
      );
      dispatch('getCompanyDetailsLoading', false);
      return true;
    } catch (e) {
      dispatch('getCompanyDetailsLoading', false);
      return false;
    }
  },

  async getCompanyHostnames({ commit }) {
    try {
      const hostnamesMapping = await this.$services.companies.getHostnames();
      commit(types.SET_COMPANY_HOSTNAMES, hostnamesMapping);
    } catch (error) {
      commit(types.SET_COMPANY_HOSTNAMES, '');
    }
  },

  async getCompanyReviewers({ commit }) {
    const reviewers = await this.$services.companies.getReviewers();
    commit(types.SET_REVIEWERS, reviewers);
  },

  async getContributors({ commit, rootGetters }) {
    try {
      const knowledgeId = rootGetters['knowledgeModule/focusKnowledgeId'];

      const contributors = await this.$services.users.getUsersWithPermissions(
        ['UPDATE_CONTENT', 'VERIFY_CONTENT'],
        knowledgeId,
      );
      commit(types.SET_CONTRIBUTORS, contributors);
    } catch (e) {
      return e;
    }
  },

  async getAllContributors({ commit }) {
    try {
      const contributors = await this.$services.users.getUsersWithPermissions([
        'UPDATE_CONTENT',
        'VERIFY_CONTENT',
      ]);
      commit(types.SET_ALL_CONTRIBUTORS, contributors);
    } catch (e) {
      return e;
    }
  },

  checkUserValidity(_, email) {
    return this.$services.users.checkValidity(email);
  },

  // EMPLOYEES

  updateEmployeesTableFilters({ commit }, filters) {
    commit(types.SET_EMPLOYEES_TABLE_FILTERS, filters);
  },

  updateModalEmployeesTableFilters({ commit }, filters) {
    commit(types.SET_MODAL_EMPLOYEES_TABLE_FILTERS, filters);
  },

  updateShowModalEmployees({ commit }, show) {
    commit(types.SET_SHOW_MODAL_EMPLOYEES, show);
  },

  getEmployeesDetails({ commit, getters }) {
    return fetchEmployeesData(
      commit,
      getters.employeesLoadingMutationType,
      getters.employeesUsersMutationType,
      'employees',
      this.$services.companies.getDetails(getters.employeesFocusTableFilters, {
        skipEmployees: false,
      }),
    );
  },

  getEmployees(_, { search, page }) {
    return this.$services.companies.getDetails(
      { currentPage: page, totalPageCount: 20, filters: {}, sort: {}, search },
      {
        skipEmployees: false,
        skipPendingEmployees: true,
        skipGroups: true,
        skipRoles: true,
        skipLabels: true,
      },
    );
  },

  deleteManyEmployees({ commit, getters }, userIds) {
    return fetchEmployeesData(
      commit,
      getters.employeesLoadingMutationType,
      getters.employeesUsersMutationType,
      'employees',
      this.$services.users.deleteMany(
        userIds,
        getters.employeesFocusTableFilters,
        {
          skipEmployees: false,
        },
      ),
    );
  },

  async updateManyEmployeesGroup(
    { commit, getters, dispatch },
    { userIds, groupIds, pending },
  ) {
    commit(types.SET_USERS_FOR_ADMINISTRATION_TABLE_LOADING, true);
    const queryParameters = await dispatch(
      'getFormattedUsersForAdministrationTableQueryParameter',
    );
    const res = await fetchEmployeesData(
      commit,
      getters.usersForAdministrationLoadingMutationType,
      getters.usersForAdministrationMutationType,
      pending ? 'pendingEmployees' : 'employees',
      this.$services.users.updateManyGroups(
        userIds,
        groupIds,
        queryParameters,
        {
          skipEmployees: pending,
          skipPendingEmployees: !pending,
        },
      ),
    );
    const employees = pending ? res.pendingEmployees : res.employees;
    commit(types.SET_USERS_FOR_ADMINISTRATION_TABLE, employees);
    commit(types.SET_USERS_FOR_ADMINISTRATION_TABLE_LOADING, false);
  },

  async updateManyEmployeesRole(
    { commit, getters, dispatch },
    { userIds, roleId, pending },
  ) {
    commit(types.SET_USERS_FOR_ADMINISTRATION_TABLE_LOADING, true);
    const queryParameters = await dispatch(
      'getFormattedUsersForAdministrationTableQueryParameter',
    );
    const res = await fetchEmployeesData(
      commit,
      getters.usersForAdministrationLoadingMutationType,
      getters.usersForAdministrationMutationType,
      pending ? 'pendingEmployees' : 'employees',
      this.$services.users.updateManyRoles(userIds, roleId, queryParameters, {
        skipEmployees: pending,
        skipPendingEmployees: !pending,
      }),
    );
    const employees = pending ? res.pendingEmployees : res.employees;
    commit(types.SET_USERS_FOR_ADMINISTRATION_TABLE, employees);
    commit(types.SET_USERS_FOR_ADMINISTRATION_TABLE_LOADING, false);
  },

  async updateManyEmployeesLabels(
    { commit, getters, dispatch },
    { userIds, labelIds, pending },
  ) {
    commit(types.SET_USERS_FOR_ADMINISTRATION_TABLE_LOADING, true);
    const queryParameters = await dispatch(
      'getFormattedUsersForAdministrationTableQueryParameter',
    );
    const res = await fetchEmployeesData(
      commit,
      getters.usersForAdministrationLoadingMutationType,
      getters.usersForAdministrationMutationType,
      pending ? 'pendingEmployees' : 'employees',
      this.$services.users.updateManyLabels(
        userIds,
        labelIds,
        queryParameters,
        {
          skipEmployees: pending,
          skipPendingEmployees: !pending,
        },
      ),
    );
    const employees = pending ? res.pendingEmployees : res.employees;
    commit(types.SET_USERS_FOR_ADMINISTRATION_TABLE, employees);
    commit(types.SET_USERS_FOR_ADMINISTRATION_TABLE_LOADING, false);
  },

  async exportUsers({ commit }, { isPending }) {
    commit(types.EXPORT_USER_LOADING, { isPending, loading: true });
    await this.$services.exportUsers.exportUsers(isPending);
    commit(types.EXPORT_USER_LOADING, { isPending, loading: false });
  },

  syncEmployeeWithHelpdesk({ commit, getters }, { userId, helpdesk }) {
    return fetchEmployeesData(
      commit,
      getters.usersForAdministrationLoadingMutationType,
      getters.usersForAdministrationMutationType,
      'employees',
      this.$services.users.syncWithHelpdesk(
        userId,
        helpdesk,
        getters.usersForAdministrationTableQueryParameter,
      ),
    );
  },

  getFormattedUsersForAdministrationTableQueryParameter({ state }) {
    let usersForAdministrationTableQueryParameterCopy = {
      ...state.usersForAdministrationTableQueryParameter,
    };

    // We need to remove path and type from queryParameters
    usersForAdministrationTableQueryParameterCopy.filters =
      usersForAdministrationTableQueryParameterCopy.filters.map((el) => {
        return { key: el.key, values: el.values };
      });

    return usersForAdministrationTableQueryParameterCopy;
  },

  resetEmployeePasswordFromAdmin(_, userId) {
    return this.$services.users.resetPasswordFromAdmin(userId);
  },

  // PENDING EMPLOYEES
  inviteUsers({ commit, getters }, { users }) {
    return fetchEmployeesData(
      commit,
      getters.pendingEmployeesLoadingMutationType,
      getters.pendingEmployeesUsersMutationType,
      'pendingEmployees',
      this.$services.users.invite(
        users,
        getters.pendingEmployeesFocusTableFilters,
      ),
    );
  },

  async renewUsersInvitations({ commit, getters, dispatch }, { invitedUsers }) {
    const queryParameters = await dispatch(
      'getFormattedUsersForAdministrationTableQueryParameter',
    );

    const { pendingEmployees } = await fetchEmployeesData(
      commit,
      getters.pendingEmployeesLoadingMutationType,
      getters.pendingEmployeesUsersMutationType,
      'pendingEmployees',
      this.$services.users.renewInvitations(invitedUsers, queryParameters),
    );
    return commit(types.SET_USERS_FOR_ADMINISTRATION_TABLE, pendingEmployees);
  },

  getPendingEmployeesDetails({ commit, getters }) {
    return fetchEmployeesData(
      commit,
      getters.pendingEmployeesLoadingMutationType,
      getters.pendingEmployeesUsersMutationType,
      'pendingEmployees',
      this.$services.companies.getDetails(
        getters.pendingEmployeesFocusTableFilters,
        {
          skipPendingEmployees: false,
        },
      ),
    );
  },

  async deleteManyPendingEmployees({ commit, getters }, userIds) {
    const res = await fetchEmployeesData(
      commit,
      getters.pendingEmployeesLoadingMutationType,
      getters.pendingEmployeesUsersMutationType,
      'pendingEmployees',
      this.$services.users.deleteMany(
        userIds,
        getters.pendingEmployeesFocusTableFilters,
        {
          skipPendingEmployees: false,
        },
      ),
    );

    commit(types.SET_ROLES, { roles: res.roles });
    commit(types.SET_GROUPS, { groups: res.userGroups });

    return res;
  },

  async updateManyPendingEmployeesGroup(
    { commit, getters },
    { userIds, groupIds },
  ) {
    const res = await fetchEmployeesData(
      commit,
      getters.pendingEmployeesLoadingMutationType,
      getters.pendingEmployeesUsersMutationType,
      'pendingEmployees',
      this.$services.users.updateManyGroups(
        userIds,
        groupIds,
        getters.pendingEmployeesFocusTableFilters,
        {
          skipPendingEmployees: false,
        },
      ),
    );
    commit(types.SET_GROUPS, { groups: res.userGroups });
    return res;
  },

  async updateManyPendingEmployeesRole(
    { commit, getters },
    { userIds, roleId },
  ) {
    const res = await fetchEmployeesData(
      commit,
      getters.pendingEmployeesLoadingMutationType,
      getters.pendingEmployeesUsersMutationType,
      'pendingEmployees',
      this.$services.users.updateManyRoles(
        userIds,
        roleId,
        getters.pendingEmployeesFocusTableFilters,
        {
          skipPendingEmployees: false,
        },
      ),
    );
    commit(types.SET_ROLES, { roles: res.roles });
    return res;
  },

  // Users Administration

  async getUsersForAdministrationFiltered({ commit, dispatch }) {
    const pending = this.$router.currentRoute.name === 'pending-administrate';
    const queryParameters = await dispatch(
      'getFormattedUsersForAdministrationTableQueryParameter',
    );
    try {
      commit(types.SET_USERS_FOR_ADMINISTRATION_TABLE_LOADING, true);
      const res = await this.$services.companies.getDetails(queryParameters, {
        skipEmployees: pending,
        skipPendingEmployees: !pending,
        skipGroups: true,
        skipRoles: true,
        skipLabels: true,
      });
      const employees = pending ? res.pendingEmployees : res.employees;
      commit(types.SET_USERS_FOR_ADMINISTRATION_TABLE, employees);
    } catch (e) {
      return e;
    } finally {
      commit(types.SET_USERS_FOR_ADMINISTRATION_TABLE_LOADING, false);
    }
  },

  addToUsersAdministrationFilter({ commit, state }, newFilter) {
    try {
      const filtersCopy =
        state.usersForAdministrationTableQueryParameter.filters;

      let oldFilterIdx = filtersCopy.findIndex((f) => {
        return f.key === newFilter.key;
      });
      if (oldFilterIdx > -1) {
        if (newFilter.values === null || newFilter.values.length === 0) {
          filtersCopy.splice(oldFilterIdx, 1);
        } else {
          filtersCopy[oldFilterIdx] = newFilter;
        }
      } else if (
        newFilter.values &&
        ((Array.isArray(newFilter.values) && !!newFilter.values.length) ||
          !!newFilter.values)
      ) {
        filtersCopy.push(newFilter);
      }
      commit(types.ADD_TO_USERS_ADMINISTRATION_FILTER, filtersCopy);
    } catch (e) {
      return e;
    }
  },

  handleUsersSearch({ commit }, search) {
    commit(types.ADD_SEARCH_TO_FILTERS, { search });
  },

  async searchEditorUsers(
    _,
    { page, pageSize, filters, sort, search, permissions },
  ) {
    try {
      const res = await this.$services.users.searchEditorUsers(
        page,
        pageSize,
        filters,
        sort,
        search,
        permissions,
      );
      return res;
    } catch (e) {
      return e;
    }
  },
  async getUserById(_, { id }) {
    try {
      const res = await this.$services.users.get(id);
      return res;
    } catch (e) {
      return e;
    }
  },

  getUsersByIds(_, ids) {
    try {
      return this.$services.users.getByIds(ids);
    } catch (e) {
      return e;
    }
  },

  emptyFiltersSearch({ commit }) {
    commit(types.EMPTY_FILTERS_SEARCH);
  },

  async updateUser({ commit, state, rootGetters, dispatch }, { user }) {
    const usersForAdministrationCopy = [...state.usersForAdministration];
    try {
      commit(types.SET_USERS_FOR_ADMINISTRATION_TABLE_LOADING, true);
      const updatedUser = await this.$services.users.updateUser(user);

      // SELF UPDATE USERNAME
      const { userId } = rootGetters;
      if (updatedUser.id === userId) {
        dispatch('updateUsername', updatedUser.username, { root: true });
      }

      const userIdx = usersForAdministrationCopy.findIndex(
        (userCopy) => userCopy.id === updatedUser.id,
      );
      if (userIdx > -1) usersForAdministrationCopy[userIdx] = updatedUser;

      commit(types.SET_USERS_FOR_ADMINISTRATION_TABLE, {
        results: usersForAdministrationCopy,
      });
      commit(types.SET_USERS_FOR_ADMINISTRATION_TABLE_LOADING, false);
    } catch (e) {
      return e;
    }
  },

  async deleteUser({ commit, state, dispatch }, { userIds }) {
    const usersForAdministrationCopy = [...state.usersForAdministration];

    try {
      commit(types.SET_USERS_FOR_ADMINISTRATION_TABLE_LOADING, true);
      const queryParameters = dispatch(
        'getFormattedUsersForAdministrationTableQueryParameter',
      );
      await this.$services.users.deleteMany(userIds, queryParameters, {
        skipEmployees: false,
      });
      userIds.map((userId) => {
        const userIdx = usersForAdministrationCopy.findIndex(
          (userCopy) => userCopy.id === userId,
        );
        if (userIdx > -1) usersForAdministrationCopy.splice(userIdx, 1);
      });
      commit(types.SET_USERS_FOR_ADMINISTRATION_TABLE, {
        results: usersForAdministrationCopy,
      });
      commit(types.SET_USERS_FOR_ADMINISTRATION_TABLE_LOADING, false);
    } catch (e) {
      return e;
    }
  },

  async handleChangePage({ commit }, page) {
    commit(types.SET_CURRENT_PAGE, { page });
  },

  async handleChangePageSize({ commit }, pageSize) {
    commit(types.SET_PAGE_SIZE, { pageSize });
  },

  async setUserInvitations({ commit }, payload) {
    commit(types.SET_USER_INVITATIONS, payload);
  },

  /**
   * ROLES
   */
  async getCustomRoles({ commit }) {
    try {
      commit(types.SET_ROLES_LOADING, true);
      const roles = await this.$services.customRoles.readCustomRoles();

      if (!roles || !Array.isArray(roles)) throw new Error('INTERNAL_ERROR');

      commit(types.SET_ROLES, { roles });
      commit(types.SET_ROLES_LOADING, false);
      return true;
    } catch (e) {
      commit(types.SET_ROLES_LOADING, false);
      return false;
    }
  },

  async updateCustomRole({ commit, state }, { id, customRole }) {
    try {
      commit(types.SET_ROLES_LOADING, true);
      const updatedRole = await this.$services.customRoles.updateCustomRole(
        id,
        customRole,
      );

      if (typeof updatedRole !== 'object' || !updatedRole)
        throw new Error('INTERNAL_ERROR');

      const rolesCopy = [...state.roles];
      const roleIndex = rolesCopy.findIndex((role) => role.id === id);
      rolesCopy[roleIndex] = updatedRole;

      commit(types.SET_ROLES, { roles: rolesCopy });
      commit(types.SET_ROLES_LOADING, false);
      return true;
    } catch (e) {
      commit(types.SET_ROLES_LOADING, false);
      return false;
    }
  },

  async createCustomRole({ commit, state }, customRole) {
    try {
      commit(types.SET_ROLES_LOADING, true);
      const newRole = await this.$services.customRoles.createCustomRole(
        customRole,
      );

      if (typeof newRole !== 'object' || !newRole)
        throw new Error('INTERNAL_ERROR');

      const rolesCopy = [...state.roles];
      rolesCopy.push(newRole);

      commit(types.SET_ROLES, { roles: rolesCopy });
      commit(types.SET_ROLES_LOADING, false);
      return true;
    } catch (e) {
      commit(types.SET_ROLES_LOADING, false);
      return false;
    }
  },

  async deleteCustomRole({ commit, state }, id) {
    try {
      commit(types.SET_ROLES_LOADING, true);
      const res = await this.$services.customRoles.deleteCustomRole(id);

      if (!res) throw new Error('INTERNAL_ERROR');

      const rolesCopy = [...state.roles];
      const newRoles = rolesCopy.filter((role) => role.id !== id);

      commit(types.SET_ROLES, { roles: newRoles });
      commit(types.SET_ROLES_LOADING, false);
      return true;
    } catch (e) {
      commit(types.SET_ROLES_LOADING, false);
      return false;
    }
  },

  /**
   * GROUPS
   */

  async getCompanyGroups({ commit }) {
    try {
      commit(types.SET_GROUPS_LOADING, true);
      const groups = await this.$services.companyGroups.get();
      if (!groups || !Array.isArray(groups)) throw new Error('INTERNAL_ERROR');

      commit(types.SET_GROUPS, { groups });
    } catch (e) {
      commit(types.SET_GROUPS, { groups: null });
    } finally {
      commit(types.SET_GROUPS_LOADING, false);
    }
  },

  async updateCompanyGroup({ commit }, { id, name, knowledgeIds, reachable }) {
    try {
      commit(types.SET_GROUPS_LOADING, true);
      const userGroups = await this.$services.companyGroups.update({
        id,
        name,
        knowledgeIds,
        reachable,
      });
      if (!userGroups) throw new Error('INTERNAL_ERROR');

      if (!Array.isArray(userGroups)) throw new Error('INTERNAL_ERROR');

      commit(types.SET_GROUPS, { groups: userGroups });
      commit(types.SET_GROUPS_LOADING, false);
      return true;
    } catch (e) {
      commit(types.SET_GROUPS_LOADING, false);
      return false;
    }
  },

  async createCompanyGroup({ commit }, { name, knowledgeIds, reachable }) {
    try {
      commit(types.SET_GROUPS_LOADING, true);
      const userGroups = await this.$services.companyGroups.create({
        name,
        knowledgeIds,
        reachable,
      });

      if (!userGroups) throw new Error('INTERNAL_ERROR');

      if (!Array.isArray(userGroups)) throw new Error('INTERNAL_ERROR');

      commit(types.SET_GROUPS, { groups: userGroups });
      commit(types.SET_GROUPS_LOADING, false);
      return true;
    } catch (e) {
      commit(types.SET_GROUPS_LOADING, false);
      return false;
    }
  },

  async deleteCompanyGroup({ commit }, { id }) {
    try {
      commit(types.SET_GROUPS_LOADING, true);
      const userGroups = await this.$services.companyGroups.delete({ id });
      if (!userGroups) throw new Error('INTERNAL_ERROR');

      if (!Array.isArray(userGroups)) throw new Error('INTERNAL_ERROR');

      commit(types.SET_GROUPS, { groups: userGroups });
      commit(types.SET_GROUPS_LOADING, false);
      return true;
    } catch (e) {
      commit(types.SET_GROUPS_LOADING, false);
      return false;
    }
  },

  /**
   * USER LABELS
   */

  async getCompanyUserLabelCategories({ commit }) {
    try {
      commit(types.SET_COMPANY_USER_LABEL_CATEGORIES_LOADING, true);

      const companyUserLabelCategories =
        await this.$services.userLabels.findAllCategories();

      if (
        !companyUserLabelCategories ||
        !Array.isArray(companyUserLabelCategories)
      )
        throw new Error('INTERNAL_ERROR');

      commit(
        types.SET_COMPANY_USER_LABEL_CATEGORIES,
        companyUserLabelCategories,
      );
      commit(types.SET_COMPANY_USER_LABEL_CATEGORIES_LOADING, false);
      return true;
    } catch (e) {
      commit(types.SET_COMPANY_USER_LABEL_CATEGORIES_LOADING, false);
      return false;
    }
  },

  async createCompanyUserLabelCategory(
    { commit, state },
    { name, icon, color },
  ) {
    try {
      commit(types.SET_COMPANY_USER_LABEL_CATEGORIES_LOADING, true);

      const newUserLabelCategory =
        await this.$services.userLabels.createUserLabelCategory({
          name,
          icon,
          color,
        });

      if (typeof newUserLabelCategory !== 'object' || !newUserLabelCategory)
        throw new Error('INTERNAL_ERROR');

      const userLabelCategoriesCopy = [...state.userLabelCategories];
      userLabelCategoriesCopy.push({
        ...newUserLabelCategory,
        companyUserLabels: [],
      });

      commit(types.SET_COMPANY_USER_LABEL_CATEGORIES, userLabelCategoriesCopy);
      commit(types.SET_COMPANY_USER_LABEL_CATEGORIES_LOADING, false);
      return true;
    } catch (e) {
      commit(types.SET_COMPANY_USER_LABEL_CATEGORIES_LOADING, false);
      return false;
    }
  },

  async updateCompanyUserLabelCategory(
    { commit, state },
    { id, name, icon, color },
  ) {
    try {
      commit(types.SET_COMPANY_USER_LABEL_CATEGORIES_LOADING, true);

      const updatedUserLabelCategory =
        await this.$services.userLabels.updateUserLabelCategory({
          id,
          name,
          icon,
          color,
        });

      if (
        typeof updatedUserLabelCategory !== 'object' ||
        !updatedUserLabelCategory
      )
        throw new Error('INTERNAL_ERROR');

      const userLabelCategoriesCopy = [...state.userLabelCategories];
      const categoryIndex = userLabelCategoriesCopy.findIndex(
        (category) => category.id === id,
      );
      userLabelCategoriesCopy[categoryIndex] = updatedUserLabelCategory;

      commit(types.SET_COMPANY_USER_LABEL_CATEGORIES, userLabelCategoriesCopy);
      commit(types.SET_COMPANY_USER_LABEL_CATEGORIES_LOADING, false);
      return true;
    } catch (e) {
      commit(types.SET_COMPANY_USER_LABEL_CATEGORIES_LOADING, false);
      return false;
    }
  },

  async deleteCompanyUserLabelCategory({ commit, state }, id) {
    try {
      commit(types.SET_COMPANY_USER_LABEL_CATEGORIES_LOADING, true);

      const res = await this.$services.userLabels.deleteCategory(id);

      if (!res) throw new Error('INTERNAL_ERROR');

      const userLabelCategoriesCopy = [...state.userLabelCategories];
      const newUserLabelCategories = userLabelCategoriesCopy.filter(
        (category) => category.id !== id,
      );

      commit(types.SET_COMPANY_USER_LABEL_CATEGORIES, newUserLabelCategories);
      commit(types.SET_COMPANY_USER_LABEL_CATEGORIES_LOADING, false);
      return true;
    } catch (e) {
      commit(types.SET_COMPANY_USER_LABEL_CATEGORIES_LOADING, false);
      return false;
    }
  },

  async createCompanyUserLabel(
    { commit, state },
    { name, companyUserLabelCategoryId },
  ) {
    try {
      commit(types.SET_COMPANY_USER_LABEL_CATEGORIES_LOADING, true);

      const newUserLabel = await this.$services.userLabels.createUserLabel({
        name,
        companyUserLabelCategoryId,
      });

      if (typeof newUserLabel !== 'object' || !newUserLabel)
        throw new Error('INTERNAL_ERROR');

      const { companyUserLabelCategoryId: categoryId } = newUserLabel;

      const userLabelCategoriesCopy = [...state.userLabelCategories];

      const categoryIndex = userLabelCategoriesCopy.findIndex(
        (category) => category.id === categoryId,
      );

      if (categoryIndex === -1) throw new Error('INTERNAL_ERROR');

      const { companyUserLabels } = userLabelCategoriesCopy[categoryIndex];
      companyUserLabels.push(newUserLabel);
      userLabelCategoriesCopy[categoryIndex].companyUserLabels =
        companyUserLabels;

      commit(types.SET_COMPANY_USER_LABEL_CATEGORIES, userLabelCategoriesCopy);
      commit(types.SET_COMPANY_USER_LABEL_CATEGORIES_LOADING, false);
      return true;
    } catch (e) {
      commit(types.SET_COMPANY_USER_LABEL_CATEGORIES_LOADING, false);
      return false;
    }
  },

  async updateCompanyUserLabel(
    { commit, state },
    { id, name, companyUserLabelCategoryId },
  ) {
    try {
      commit(types.SET_COMPANY_USER_LABEL_CATEGORIES_LOADING, true);

      const updatedUserLabel = await this.$services.userLabels.updateUserLabel({
        id,
        name,
        companyUserLabelCategoryId,
      });

      if (typeof updatedUserLabel !== 'object' || !updatedUserLabel)
        throw new Error('INTERNAL_ERROR');

      const { companyUserLabelCategoryId: categoryId } = updatedUserLabel;

      const userLabelCategoriesCopy = cloneDeep(state.userLabelCategories);
      const categoryIndex = userLabelCategoriesCopy.findIndex(
        (category) => category.id === categoryId,
      );
      if (categoryIndex === -1) throw new Error('INTERNAL_ERROR');

      const { companyUserLabels } = userLabelCategoriesCopy[categoryIndex];
      const labelIndex = companyUserLabels.findIndex(
        (label) => label.id === id,
      );
      if (labelIndex === -1) throw new Error('INTERNAL_ERROR');

      companyUserLabels[labelIndex] = updatedUserLabel;
      userLabelCategoriesCopy[categoryIndex].companyUserLabels =
        companyUserLabels;

      commit(types.SET_COMPANY_USER_LABEL_CATEGORIES, userLabelCategoriesCopy);
      commit(types.SET_COMPANY_USER_LABEL_CATEGORIES_LOADING, false);
      return true;
    } catch (e) {
      commit(types.SET_COMPANY_USER_LABEL_CATEGORIES_LOADING, false);
      return false;
    }
  },

  async deleteCompanyUserLabel(
    { commit, state },
    { id, companyUserLabelCategoryId },
  ) {
    try {
      commit(types.SET_COMPANY_USER_LABEL_CATEGORIES_LOADING, true);

      const res = await this.$services.userLabels.deleteUserLabel(id);
      if (!res) throw new Error('INTERNAL_ERROR');

      const userLabelCategoriesCopy = [...state.userLabelCategories];
      const categoryIndex = userLabelCategoriesCopy.findIndex(
        (category) => category.id === companyUserLabelCategoryId,
      );
      if (categoryIndex === -1) throw new Error('INTERNAL_ERROR');

      const { companyUserLabels } = userLabelCategoriesCopy[categoryIndex];

      const newCompanyUserLabels = companyUserLabels.filter(
        (label) => label.id !== id,
      );

      userLabelCategoriesCopy[categoryIndex].companyUserLabels =
        newCompanyUserLabels;

      commit(types.SET_COMPANY_USER_LABEL_CATEGORIES, userLabelCategoriesCopy);
      commit(types.SET_COMPANY_USER_LABEL_CATEGORIES_LOADING, false);
      return true;
    } catch (e) {
      commit(types.SET_COMPANY_USER_LABEL_CATEGORIES_LOADING, false);
      return false;
    }
  },

  /**
   * COMPANY SETTINGS
   */

  async getMe() {
    try {
      const res = this.$services.users.me();

      if (!res) return null;
      return res;
    } catch (e) {
      return null;
    }
  },

  async updateUserSettings({ commit }, { familyName, givenName }) {
    try {
      const res = await this.$services.users.updateSettings({
        familyName,
        givenName,
      });

      if (typeof res !== 'object' || !res) return null;

      const { username: newUsername } = res;
      if (!newUsername) return null;

      commit(
        'UPDATE_USERNAME',
        {
          username: newUsername,
        },
        { root: true },
      );

      return res;
    } catch (e) {
      return null;
    }
  },

  async updateCompanyLogo(_, url) {
    try {
      const { logo } = await this.$services.companies.updateLogo(url);

      if (!logo) return null;
      return logo;
    } catch (e) {
      return null;
    }
  },

  async deleteCompanyLogo() {
    try {
      const { logo } = await this.$services.companies.deleteLogo();
      return logo;
    } catch (e) {
      return null;
    }
  },

  /**
   * COMPANY SYNONYMS
   */

  async getCompanySynonyms({ commit }) {
    try {
      const newSynonyms = await this.$services.companySynonyms.getAll();
      if (!newSynonyms || !Array.isArray(newSynonyms))
        throw new Error('INTERNAL_ERROR');

      commit(types.SET_COMPANY_SYNONYMS, newSynonyms);
      return true;
    } catch (e) {
      return false;
    }
  },

  async createCompanySynonyms({ commit }, { synonyms }) {
    try {
      const newSynonyms = await this.$services.companySynonyms.create(synonyms);
      if (!newSynonyms || !Array.isArray(newSynonyms))
        throw new Error('INTERNAL_ERROR');

      commit(types.SET_COMPANY_SYNONYMS, newSynonyms);
      return true;
    } catch (e) {
      return false;
    }
  },

  async updateCompanySynonyms({ commit }, { ids, aSynonym }) {
    try {
      const newSynonyms = await this.$services.companySynonyms.updateMany(
        ids,
        aSynonym,
      );
      if (!newSynonyms || !Array.isArray(newSynonyms))
        throw new Error('INTERNAL_ERROR');

      commit(types.SET_COMPANY_SYNONYMS, newSynonyms);
      return true;
    } catch (e) {
      return false;
    }
  },

  async deleteCompanySynonyms({ commit }, ids) {
    try {
      const newSynonyms = await this.$services.companySynonyms.delete(ids);
      if (!newSynonyms || !Array.isArray(newSynonyms))
        throw new Error('INTERNAL_ERROR');

      commit(types.SET_COMPANY_SYNONYMS, newSynonyms);
      return true;
    } catch (e) {
      return false;
    }
  },

  // Settings IP ADDRESSES
  // eslint-disable-next-line no-unused-vars
  getCompanyIpRanges(_) {
    return this.$services.settings.getCompanyIpRanges();
  },

  setCompanyIpRanges(_, ipRanges) {
    return this.$services.settings.setCompanyIpRanges(ipRanges);
  },

  // eslint-disable-next-line no-unused-vars
  deleteCompanyIpRanges(_) {
    return this.$services.settings.deleteCompanyIpRanges();
  },

  // Settings Mayday API
  getApiOAuthAppRegistrations() {
    return this.$services.settings.getApiOAuthAppRegistrations();
  },

  createApiOAuthAppRegistration() {
    return this.$services.settings.createApiOAuthAppRegistration();
  },

  updateApiOAuthAppRegistration(_, { id, key, value }) {
    return this.$services.settings.updateApiOAuthAppRegistration(
      id,
      key,
      value,
    );
  },

  deleteApiOAuthAppRegistration(_, id) {
    return this.$services.settings.deleteApiOAuthAppRegistration(id);
  },

  // Websockets
  setWSConnectionId({ commit }, connectionId) {
    commit(types.SET_WS_CONNECTION_ID, connectionId);
  },

  addWPSGroupPermission({ state }, { contentId, lang }) {
    if (!state.wsConnectionId) return Promise.reject('No connection id');
    return this.$services.websockets.grantPermissionToGroup({
      targetGroup: `EDIT_${contentId}_${lang}`,
      connectionId: state.wsConnectionId,
    });
  },

  updateWPSConnectedUsers({ state }, { content, status, userId, value }) {
    if (!state.wsConnectionId) return;
    const newConnectedUsers = { ...state.connectedUsers };
    const newContentConnectedUsers = newConnectedUsers[content] || [];
    let userIndex = -1;
    switch (status) {
      case 'CONNECTED':
      case 'ACK':
        if (
          newContentConnectedUsers.find((user) => user.userId === userId) ||
          !state.contributors.find((contributor) => contributor.id === userId)
        ) {
          return;
        }
        newContentConnectedUsers.push({
          userId,
          isActive: true,
        });
        break;
      case 'DISCONNECTED':
        if (!newContentConnectedUsers.length) return;
        userIndex = newContentConnectedUsers.findIndex(
          (user) => user.userId === userId,
        );
        if (userIndex > -1) {
          newContentConnectedUsers.splice(userIndex, 1);
        }
        break;
      case 'IS_IDLE':
        if (!newContentConnectedUsers.length) return;
        userIndex = newContentConnectedUsers.findIndex(
          (user) => user.userId === userId,
        );
        if (userIndex > -1) {
          newContentConnectedUsers[userIndex].isActive = !value;
        }
        break;
    }
    newConnectedUsers[content] = newContentConnectedUsers;
    state.connectedUsers = newConnectedUsers;
  },

  clearWPSConnectedUsers({ state }, content) {
    state.connectedUsers = { ...state.connectedUsers, [content]: [] };
  },
};
