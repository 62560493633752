import debounce from 'lodash.debounce';

export class DataLoader {
  constructor({ batchMethod, maxChunkSize, debounceTime }) {
    this.batchMethod = batchMethod;
    this.maxChunkSize = maxChunkSize || 10;
    this.debounceTime = debounceTime || 100;
    this.batch = [];

    this.processBatch = debounce(this._processBatch.bind(this), debounceTime);
  }

  static dataLoaderFormater(values, mapKey, orderedMapKeyValues) {
    if (!values) return [];
    const valuesMap = values.reduce((acc, val) => {
      acc[val[mapKey]] = val;
      return acc;
    }, {});

    return orderedMapKeyValues.map((key) => valuesMap[key]);
  }

  static chunkBatch(batch, chunkSize) {
    if (!batch || !chunkSize) return [];

    const chunksCount = Math.ceil(batch.length / chunkSize);
    const chunks = [];
    let chunkIdx = 0;

    while (chunkIdx < chunksCount) {
      chunks.push(
        batch.slice(chunkIdx * chunkSize, (chunkIdx + 1) * chunkSize),
      );
      chunkIdx += 1;
    }

    return chunks;
  }

  load(id) {
    if (!id) return null;
    return new Promise((resolve, reject) => {
      this.batch.push({ id, resolve, reject });
      this.processBatch();
    });
  }

  async _processBatch() {
    if (this.batch.length === 0) return;

    const batchChunks = DataLoader.chunkBatch(this.batch, this.maxChunkSize);

    // reset dataLoader batch for requests happening while
    // awaiting this batch process
    this.batch = [];

    await Promise.all(
      batchChunks.map(async (chunk) => {
        const ids = chunk.map(({ id }) => id);

        try {
          const chunkResponses = await this.batchMethod(ids);
          if (chunkResponses.length !== chunk.length)
            throw new Error('INVALID_BATCH_RESPONSE_PARSING');
          // /!\ we expect the batchMethod to parse the reponse
          // and makes sure chunkResponses.length === ids.length
          chunkResponses.forEach((item, index) => {
            chunk[index].resolve(item);
          });
        } catch (error) {
          chunk.forEach(({ reject }) => reject(error));
        }
      }),
    );
  }
}
