export default {
  async getOrgTree({ getters }, { contentId, isStaged = false }) {
    const { editingLanguage: lang } = getters;
    let descendentsWithRoot =
      await this.$services.parametricContents.getDescendents(
        contentId,
        lang,
        isStaged,
      );
    descendentsWithRoot.root['parentId'] = -1;
    descendentsWithRoot.descendents.unshift(descendentsWithRoot.root);

    const result = descendentsWithRoot.descendents.map((child) => {
      if (!child.parentId) {
        const parent = descendentsWithRoot.descendents.filter((d) =>
          d.path.includes(child.id),
        );

        child.parentId =
          parent.length > 0 ? parent[0].id : descendentsWithRoot.root.id;
      }

      // TODO refactor flowy not to handle data.content.* but child immediately
      // And automations, actions not accessible for the moment
      child.data = {
        title: isStaged ? child.stagedData.label : child.label,
        content: {
          ...child,
          path: isStaged ? child.stagedData.path : child.path,
          actions: [],
          body: '',
          tags: [],
        },
        automations: [],
      };

      child.path = isStaged ? child.stagedData.path : child.path;

      return child;
    });

    return result;
  },

  async getNodeAutomations(_, contentId) {
    const automations = await this.$services.automations.getContentAutomations(
      contentId,
    );
    return automations;
  },

  async getNodeContent(_, contentId) {
    const content = await this.$services.parametricContents.get(contentId);
    return content;
  },

  async getLightContent(_, contentId) {
    const lightContent = await this.$services.parametricContents.getLight(
      contentId,
    );
    return lightContent;
  },

  async relocateChild(_, { childId, formerParentId, newParentId }) {
    return this.$services.parametricContents.relocate(
      childId,
      formerParentId,
      newParentId,
    );
  },

  async setRedirection(_, { entityType, entityId, child, lang }) {
    return this.$services.parametricContents.setContentRedirection({
      contentId: child.id,
      entityId,
      entityType,
      lang,
    });
  },
};
