import data from './case.data.raw.js';
const staticData = require('./cases.json');

export class CaseServiceData {
  constructor(client) {
    this.client = client;
  }

  /**
   * READ QUERIES
   */

  async getCasesRoot(knowledge, lang) {
    return this.client
      .query({
        query: data.getCasesRoot,
        variables: { knowledge, lang },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getCasesRoot)
      .catch((error) => error);
  }

  async getSettingsCasesRoot(knowledge) {
    return this.client
      .query({
        query: data.getSettingsCasesRoot,
        variables: { knowledge },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getCasesRoot)
      .catch((error) => error);
  }

  async getCasesRootWithChildren(knowledge, lang, skipOrdered = false) {
    return this.client
      .query({
        query: data.getCasesRootWithChildren,
        variables: { knowledge, lang, skipOrdered },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.casesRoot)
      .catch((error) => error);
  }

  async getDetailedCaseById(
    caseId,
    skipOrdered,
    skipPath,
    lang,
    publishedContentsOnly,
  ) {
    return this.client
      .query({
        query: data.getDetailedCaseById,
        variables: {
          caseId,
          skipOrdered,
          skipPath,
          lang,
          publishedContentsOnly,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.caseParameter)
      .catch((error) => error);
  }

  async getCasesById(ids) {
    return this.client
      .query({
        query: data.getCasesById,
        variables: { ids },
      })
      .then((response) => response.data.getCasesById)
      .catch((error) => error);
  }

  async getLightCaseById(id, lang) {
    return this.client
      .query({
        query: data.getLightCaseById,
        variables: { caseId: id, lang },
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        return response.data.case;
      })
      .catch((error) => error);
  }

  async getCasesTranslationState(ids, lang) {
    return this.client
      .query({
        query: data.getCasesTranslationState,
        variables: { ids, lang },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getCasesById)
      .catch((error) => error);
  }

  async getContentsByCaseId(caseId, pageSize = 20, page = 1, search = '') {
    return this.client
      .query({
        query: data.getContentsByCaseId,
        variables: { caseId, pageSize, page, search },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.relatedContents)
      .catch((error) => error);
  }

  async getCaseResolutionsById(caseId, productId, count = 10, search) {
    return this.client
      .query({
        query: data.getCaseResolutionsById,
        variables: { caseId, productId, count, search },
      })
      .then((response) => response.data.getCaseById)
      .catch((error) => error);
  }

  async getPartialProductCaseResolutionsById(
    caseId,
    parameters,
    count = 10,
    search,
  ) {
    return this.client
      .query({
        query: data.getPartialProductCaseResolutionsById,
        variables: { caseId, parameters, count, search },
      })
      .then((response) => response.data.getCaseById)
      .catch((error) => error);
  }

  async getCasesRootByProductId(productId, knowledge) {
    return this.client
      .query({
        query: data.getCasesRootByProductId,
        variables: { productId, knowledge },
      })
      .then((response) => response.data.getCasesRoot)
      .catch((error) => error);
  }

  async getCasesRootByPartialProduct(parameters, knowledge) {
    return this.client
      .query({
        query: data.getCasesRootByPartialProduct,
        variables: { parameters, knowledge },
      })
      .then((response) => response.data.getCasesRoot)
      .catch((error) => error);
  }

  async getCaseChildren(caseId, knowledge, lang) {
    return this.client
      .query({
        query: data.getCaseChildren,
        variables: { caseId, knowledge, lang },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getCaseChildren)
      .catch((error) => error);
  }

  async getSettingsCaseChildren(caseId, knowledge, lang) {
    return this.client
      .query({
        query: data.getSettingsCaseChildren,
        variables: { caseId, knowledge, lang },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.getCaseChildren)
      .catch((error) => error);
  }

  async getCaseChildrenByProductId(caseId, productId, knowledge) {
    return this.client
      .query({
        query: data.getCaseChildrenByProductId,
        variables: { caseId, productId, knowledge },
      })
      .then((response) => response.data.getCaseChildren)
      .catch((error) => error);
  }

  async getChildrenByPartialProduct(caseId, parameters, knowledge) {
    return this.client
      .query({
        query: data.getChildrenByPartialProduct,
        variables: { caseId, parameters, knowledge },
      })
      .then((response) => response.data.getCaseChildren)
      .catch((error) => error);
  }

  async getRebuiltCasePathFromCaseId(caseId, lang) {
    return this.client
      .query({
        query: data.getRebuiltCasePathFromCaseId,
        variables: { caseId, lang },
      })
      .then((response) => response.data.caseParameter.path)
      .catch((error) => error);
  }

  async getOrderedChildrenAndContents(caseId, knowledge, childrenOrder, lang) {
    return this.client
      .query({
        query: data.getOrderedChildrenAndContents,
        variables: { caseId, knowledge, childrenOrder, lang },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.children)
      .catch((error) => error);
  }

  /**
   * CREATE QUERIES
   */

  async addCase(parentId, label, lang) {
    return this.client
      .mutate({
        mutation: data.addCase,
        variables: { parentId, label, lang },
      })
      .then((response) => response.data.addCase)
      .catch((error) => error);
  }

  async createRootCase(label, knowledge, knowledgeId, lang) {
    return this.client
      .mutate({
        mutation: data.createRootCase,
        variables: { label, knowledge, knowledgeId, lang },
      })
      .then((response) => response.data.rootCase)
      .catch((error) => error);
  }

  /**
   * UPDATE QUERIES
   */

  async updateCase(caseId, label, body, childrenOrder, lang, skipOrdered) {
    return this.client
      .mutate({
        mutation: data.updateCase,
        variables: { caseId, label, body, childrenOrder, lang, skipOrdered },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.updateCase)
      .catch((error) => error);
  }

  async updateCaseIcon(caseId, iconType, value, color, field) {
    return this.client
      .mutate({
        mutation: data.updateCaseIcon,
        variables: { caseId, iconType, value, color, field },
      })
      .then((response) => response.data.updateCaseIcon)
      .catch((error) => error);
  }

  async moveCase(caseId, newFullPath, moveToRoot, newIndex) {
    return this.client
      .mutate({
        mutation: data.moveCase,
        variables: { caseId, newFullPath, moveToRoot, newIndex },
      })
      .then((response) => response.data.moveCase)
      .catch((error) => error);
  }

  async unsetLanguage(caseId, lang) {
    return this.client
      .mutate({
        mutation: data.unsetCaseLanguage,
        variables: { caseId, lang },
      })
      .then((response) => response.data.unsetCaseLanguage)
      .catch((error) => error);
  }

  async restoreCaseArchive(archiveId, parent) {
    return this.client
      .mutate({
        mutation: data.restoreCaseArchive,
        variables: { archiveId, parent },
        fetchPolicy: 'no-cache',
      })
      .then((response) => response.data.restoreCaseArchive)
      .catch((error) => error);
  }

  async deleteCaseArchive(archiveId) {
    return this.client
      .mutate({
        mutation: data.deleteCaseArchive,
        variables: { archiveId },
      })
      .then((response) => response.data.deleteCaseArchive)
      .catch((error) => error);
  }

  /**
   * DELETE QUERIES
   */

  async deleteCase(caseId, softDeleted) {
    return this.client
      .mutate({
        mutation: data.deleteCase,
        variables: { caseId, softDeleted },
      })
      .then((response) => response.data.deleteCase)
      .catch((error) => error);
  }

  async deleteCaseIcon(caseId, field) {
    return this.client
      .mutate({
        mutation: data.deleteCaseIcon,
        variables: { caseId, field },
      })
      .then((response) => response.data.deleteCaseIcon)
      .catch((error) => error);
  }

  /**
   * FILTER QUERIES
   */
  async getByKnowledge(value) {
    return staticData.find((c) => c.value === value);
  }
}
