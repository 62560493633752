import { DataLoader } from '@/utils/dataloader';

export class ContentDataLoaders {
  constructor(contentService) {
    this.getByIdDataLoaders = {};
    this.getById = (lang) => {
      if (!this.getByIdDataLoaders[lang]) {
        this.getByIdDataLoaders[lang] = new DataLoader({
          batchMethod: this.batchById(contentService, lang),
        });
      }
      return this.getByIdDataLoaders[lang];
    };
  }

  batchById = (contentService, lang) => async (ids) => {
    try {
      const uniqueIds = [...new Set(ids)];
      const contents = await contentService.getManyLight(uniqueIds, lang);
      return DataLoader.dataLoaderFormater(contents, 'id', ids);
    } catch (err) {
      console.log(err);
      return [];
    }
  };
}
